@import "../../../../assets/style-params.module";

.DeleteItemsModalContainer {
    height: 208px;
    width: 480px;

    :global(.message) {
        margin-bottom: 22px;
    }

    :global(.close) {
        width: 98px;
    }

    :global(.delete) {
        @include button-error-type();
        width: 120px;
        margin-left: 8px;
    }
}
