@import "../../../assets/style-params.module";

.HelpDropdown {
    display: block;
    font-size: 13px;

    :global(.label) {
        height: 48px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:global(.dot) {
            position: relative;

            &:after {
                position: absolute;
                left: 13px;
                transform: translate(1px, -10px);
                content: "\25CF";
                color: #FF0000;
            }
        }
    }

    :global(.dropdown) {
        user-select: none;
        background-color: #fff;
        border: 1px solid $color-alabaster;
        border-radius: 2px;
        width: 206px;
        box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
        right: clamp(0px, calc(100vw - 800px), 190px);
        top: 48px;
        position: absolute;
        z-index: 7;
        transform: translateY(-20px);
        transition: all 300ms;
        visibility: hidden;
        opacity: 0;
    }

    &:global(.open) {
        :global(.dropdown) {
            visibility: visible;
            display: block;
            opacity: 1;
            transform: translateY(0);
        }
    }
}
