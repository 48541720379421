.modalPopupOverlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000;
    background: rgba(0, 32, 50, 0.5);
}

.modalPopup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;

    :global(.container) {
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
}

.modalPopupInnerContainer {
    position: fixed;
    z-index: 1001;
}

.closeButton {
    color: #303030;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    max-width: 90px;

    img {
        height: 24px;
        vertical-align: top;
    }
}
