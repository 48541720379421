@import "../../../assets/style-params.module";

.scopePicker {
    button {
        width: 100px;
        border: 1px solid $color-dove-grey;
        border-radius: 2px;
        @include font-label-copy-default();
    }
}

.scopeDropdownPicker {
    :global(.option):global(.disabled) {
        color: $color-dove-grey;
        cursor: not-allowed;
    }
}

.editModal {
    align-items: center;

    :global(.modal-inner-container) {
        top: unset !important;
        display: flex;
        flex-grow: 1;

        :global(.content) {
            width: 500px;
            display: flex;
            padding-bottom: 15px;

            >*:not(:last-child) {
                margin-right: 8px;
            }

            input {
                background: #fff;
                display: inline-block;
                font-size: 13px;
                line-height: 16px;
                outline: 0px;
                padding: 0 8px;
                color: #303030;
                flex: 1;
                border: 1px solid $color-dove-grey;
                border-radius: 2px;
                @include font-label-copy-default();
            }
        }

        :global(.footer) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-top: 15px;
            border-top: 1px solid #dadada;

            :global(.cancel-btn) {
                margin-left: auto;
                margin-right: 8px;
            }
        }
    }
}

.confirmModal {
    left: calc(50% - 225px);
    top: calc(50% - 128px);
    width: 410px;

    :global(.headerCloseButton) {
        span {
            display: none;
        }
    }

    :global(.closeButton) {
        background-color: $color-curious-blue;
        color: white;
    }
}
