@import "../../../../assets/style-params.module";

.dropdownContent {
    background: white;
    width: 256px;
    padding: 16px;
    box-shadow: 0 2px 4px 0px rgba(64, 64, 66, 0.5);
    border-radius: 2px;

    :global(.selected) {
        :global(.column):first-child {
            background: $color-summer-sky;
            border: none;
        }
    }

    :global(.widget-width-title) {
        font-size: 13px;
        line-height: 16px;
        text-align: left;
        font-weight: 600;
        margin-top: 0;
    }
}
