.InfoMessage {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 156px;

    img {
        width: 96px;
        height: 96px;
    }

    :global(.info-title) {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 12px;
    }

    :global(.info-text) {
        margin-top: 8px;
        width: 336px;
        text-align: center;
    }
}
