.copyButton {
    height: 24px;
    line-height: 24px;
    width: 119px;
    position: absolute;
    background-color: transparent;
    overflow: hidden;
    transition: width 300ms;
    text-align: left;
    display: block;
    z-index: 2;

    :global(.label) {
        position: relative;
        transition: top 300ms;
        left: 8px;
    }

    :global(.callToAction) {
        top: 0px;
    }

    :global(.actionCalled) {
        top: 0px;
        width: 172px;
    }

    img {
        position: absolute;
        width: 15px;
        height: 15px;
        left: 4px;
        top: 4px;
        user-select: none;
    }

    &:active {
        img {
            filter: brightness(0) invert(1);
        }
    }

    &:global(.recentlyClicked) {
        width: 172px;

        :global(.callToAction) {
            top: -20px;
        }

        :global(.actionCalled) {
            top: -24px;
        }
    }
}
