.GeoPickerCard {
    background: #fff;
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 400px;
    width: 388px;

    :global(.search-box) {
        width: 100%;
        margin-bottom: 8px;
    }

    > :global(.option) {
        padding: 4px;

        &:hover {
            background: #29abe20d;
        }
    }
}
