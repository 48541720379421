@import "../../../assets/style-params.module";
.AddCreateDropdown {
    margin: 8px 0 0 0;
}
.DropdownButtonClassName {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: #FFC600;
    border-radius: 2px;
    color: black;
    background-color: #FFC600;
    cursor: pointer;
    :global(.chevron) {
        filter: brightness(0);
    }
    &:active {
        color: white;
        background-color: #FFC600;
    }
    &:hover {
        &:not(:global(.disabled)) {
            color: black;
            border-color: #D9A800;
            background-color: #D9A800;
        }
    }
}