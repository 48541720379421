@import "../../assets/style-params.module";

.confirmModal {
    padding: 24px;
    box-shadow: 0 2px 4px 0 rgba(64, 64, 66, 0.5);
    border: 1px solid $color-alabaster;
    border-radius: 2px;
    background-color: #FFFFFF;
    position: fixed;
    width: inherit;

    :global(.header) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > span {
            font-size: 24px;
            color: #000;
        }

        :global(.headerCloseButton) {
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            user-select: none;

            & > span {
                margin-right: 4px;
                color: $color-black;
                font-size: 18px;
            }
        }
    }

    :global(.content) {
        margin-top: 25px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E2E4E6;
        color: $color-black;
        font-size: 13px;
    }

    :global(.footer) {
        display: flex;
        margin-top: 23px;

        :global(.error-messages) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            margin-right: 15px;
            padding: 0 6px;
            background-color: #F7EAEA;
            color: $color-well-read;
            border-radius: 2px;
        }

        :global(.closeButton) {
            margin: 0 8px 0 auto;
        }
    }
}
