.AuthedEntitiesListLoading {
    display: flex;
    flex-direction: column;
    margin: 16px 0;
}

.PlaceholderRow {
    display: flex;
    height: 32px;
    align-items: center;
    justify-content: space-between;

    :global(.label-placeholder) {
        width: 240px;
        height: 24px;
    }

    :global(.icon-placeholders) {
        display: flex;
        align-items: center;

        :global(.icon-placeholder) {
            width: 24px;
            height: 24px;
            margin-left: 4px;
        }
    }
}
