@import "../../../assets/style-params.module";

.brandsPopup {
    background-color: #ffffff;
    height: 520px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    flex-direction: column;

    :global(.search-box) {
        width: 100%;
    }

    :global(.bottom-container) {
        border-top: solid 1px #DADADA;
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    :global(.main-content-cards-container) {
        display: flex;
        flex-direction: column;
        flex: 1;

        :global(.main-content-card) {
            border-color: $color-dove-grey;
            cursor: pointer;
            margin-bottom: 16px;
            padding: 16px;

            :global(.main-content-card-header) {
                @include font-modal-title-centered();
                font-size: 20px;
                margin-bottom: 8px;
            }

            :global(.main-content-card-content) {
                @include font-label-copy-grey();
                margin-bottom: 8px;
            }
        }
    }

    :global(.bulk-info) {
        border-top: unset;
        margin-bottom: 16px;

        b {
            text-decoration: underline;
            text-decoration-color: #DADADA;
            cursor: pointer;
        }
    }
}

.modalWrapper {
    z-index: 10000;

    :global(.modal-inner-container) {
        z-index: 10000;
        width: 525px;
        height: 520px;
    }

    :global(.modal-overlay) {
        z-index: 10000;
    }
}
