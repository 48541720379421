.groupPicker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    label {
        padding-bottom: 4px;
        font-weight: bold;
    }
}

.additionalFiltersDropdown {
    > div {
        > div {
            width: 300px;
            overflow-y: scroll;
            height: 300px;
        }
    }
}

.tooltip {
    :global(.tooltipBody) {
        max-width: 90%;
    }
}
