@import "../../../../assets/style-params.module";

.personasTab {
    display: flex;
    flex-direction: column;
    padding: 15px 15px 0 15px;
    overflow: auto;

    :global(.search-box) {
        width: 100%;
    }

    :global(.scroll-wrapper) {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 15px 0;
        margin-top: 15px;
        border-top: solid 1px $color-silver-light-third;
        border-bottom: solid 1px $color-silver-light-third;
        overflow: auto;

        :global(.picker-row) {
            padding: 8px;
        }
    }
}

.personasTabPlaceholder {
    width: 100%;
    height: 100%;
}
