.tab {
    cursor: pointer;
    display: inline-block;
    line-height: 24px;
    min-height: 24px;
    padding: 16px 12px;
    user-select: none;
    min-width: 35px;
    text-align: center;

    &:hover {
        background: rgba(41, 171, 226, 0.15);
    }

    &:global(.divided) {
        border-right: 1px solid white;
    }

    &:global(.current) {
        background: white;
        border-right: 0px none;
        border-top: 4px solid #29ABE2;
        padding-top: 12px;
    }

    &:global(.text) {
        color: #303030;
        padding-left: 16px;
        padding-right: 16px;
    }

    &:global(.locked) {
        padding-left: 5px;
        padding-right: 5px;
    }

    &:global(.disabled) {
        color: #c8c8c8;
        cursor: not-allowed;

        &:hover {
            background: transparent !important;
        }
    }

    &:global(.dot) {
        position: relative;

        &:after {
            position: absolute;
            transform: translate(1px, -10px);
            content: "\25CF";
            color: #FF0000;
        }
    }

    &:last-child {
        border-right: 0px none;
    }

    > img:global(.icon) {
        height: 24px;
        vertical-align: top;
    }

    &:global(.variant-48) {
        line-height: 16px;
        min-height: 16px;
    }

    &:global(.icon-title) {
        > img:global(.icon) {
            height: unset;
            padding: 5px;
            filter: invert(1);
        }
    }

    :global(.container) {
        display: flex;
        align-items: center;
    }
}

.tooltip {
    display: flex;
    justify-content: center;
    align-items: center;

    > img:global(.icon-small) {
        width: 16px;
        height: 16px;
    }
}
