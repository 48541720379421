@import "../../../../assets/style-params.module";

.XtoDatePeriodModal {
    z-index: 3000;

    :global(.closeButton) {
        background-color: #fff;
        border: 1px solid $color-5;
        color: $color-5;
    }

    :global(.content) {
        display: flex;
        justify-content: center;
    }
}
