@import "../../../../assets/style-params.module";

.listsTab {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 544px;
    overflow: hidden;

    :global(.placeholder-wrapper) {
        flex: 1 auto;
        gap: 40px;
        display: flex;
        max-height: 16px;
        margin-bottom: 16px;
    }

    :global(.placeholder) {
        height: 16px;
        width: 100%;

        &:global(.small) {
            width: 82px;
        }
    }

    :global(.categories-empty) p {
        margin: 0;
    }

    :global(.empty-msg) {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 40px;
        top: unset;

        :global(.message) {
            line-height: 24px;
        }
    }

    :global(.scroll-wrapper) {
        margin-bottom: 0 !important;
    }

    input {
        background: #fff;
        display: inline-block;
        font-size: 13px;
        line-height: 16px;
        outline: 0px;
        padding: 7px 8px;
        border: 1px solid #E0DADE;
        border-radius: 2px;
        color: #303030;
        flex: 1;
    }

    :global(.campaign-row) {
        display: flex;

        > div:first-of-type {
            flex: 5;
            min-width: 0;
        }

        :global(.addition) {
            color: #707070;
        }

        :global(.icon) {
            margin-top: 4px;
            cursor: pointer;
            transition: transform 0.3s ease;

            &:global(.closed-btn) {
                transform: rotateZ(-90deg);
            }
        }
    }

    span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 8px;
    }
}

.Tooltip {
    max-width: 55%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adsList {
    padding: 0 8px 0 8px;
    position: relative;
    width: 100%;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 20px;
        left: 0;
    }
}

.idLabel {
    flex: 1;
    text-align: right;
    color: #707070;
    padding: 8px;
}

.campaignRow {
    :global(.radio) {
        position: static;
    }

    :global(.radio-platform-icons) {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }

    :global(.label) {
        margin-left: 5px;
        line-height: 16px;
        max-width: 52%;
    }
}

.adRow {
    display: flex;
    align-items: flex-start;
    padding-right: 8px;

    &:first-child {
        &:before {
            height: 16px;
            margin-top: 0px;
        }
    }

    &:before {
        content: "";
        display: block;
        width: 42px;
        height: 32px;
        border: 1.5px dotted #cbcbcb;
        border-top: 0;
        border-right: 0;
        flex: 0 0 auto;
        margin-top: -16px;
    }

    :global(.addition) {
        color: #707070;
    }

    :global(.radio) {
        position: static;
        margin-right: 5px;
    }

    :global(.label) {
        margin-left: 4px;
        line-height: 16px;
        max-width: 55%;
    }

    :global(.radio-platform-icons) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    > div:first-of-type {
        flex: 5;
        min-width: 0;
    }
}

.TryAgain {
    color: $color-summer-sky;
    text-decoration: underline;
    cursor: pointer;
}

.WidgetNotIdealState {
    flex: 1;
}

.CheckboxList {
    flex: 1;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.NothingMatch {
    padding: 8px;
    padding-top: 0;
}

.ResultsDescription {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    height: 32px;
    width: auto;
    align-items: center;
    margin: 8px 8px 0 8px;
}

.customRow {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
    padding: 7.5px 4px;

    > div:last-child :global(.additional) {
        flex: 1;
        text-align: right;
        color: #707070;
    }
}

.innerAddition {
    display: flex;
    text-align: center;
    gap: 7px;
}
