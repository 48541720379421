.DigitalV2CategoriesTab {
    height: 100%;
    padding: 15px 15px 0 15px;
    margin-bottom: -8px;

    :global(.search-box-container) {
        display: flex;

        :global(.search-wrapper) {
            width: 304px;
            margin-top: 0;
        }
    }

    :global(.tree-structure-container) {
        height: 284px;
        overflow-y: auto;
        border: 0;
        padding: 10px;
    }
}
