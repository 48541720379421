.iconWrapper {
    position: relative;
}

.warningIcon {
    position: absolute;
    right: -2px;
    top: -3px;
    width: 15px;
}
