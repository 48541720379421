@import "../../assets/style-params.module";

.card {
    background: $color-solid-white;
    border: 1px solid $color-alabaster;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 15px;

    &:global(.sh__is-screenshotting) {
        :global(.sh__saveasimagebutton) {
            display: none;
        }
    }
}

.cardTitle {
    width: 100%;
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 24px;
    margin: 0px;
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
