@import "../../assets/style-params.module";

.warning {
    background: rgba(176, 48, 48, 0.1);
    border-radius: 2px;
    color: $color-well-read;
    padding: 12px 16px;
    margin-bottom: 16px;
}

:global(.tv-warning) {
    background: $color-tv-warning !important;
    color: $color-curious-blue !important;
}
