@import "../../../assets/animations.module";
@import "../../../assets/style-params.module";

.notificationsPopup {
    background-color: #ffffff;
    height: 472px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    flex-direction: column;

    :global(.loader-container) {
        align-items: center;
        display: flex;
        justify-content: center;
        flex: 1;

        :global(.loader) {
            height: 48px;
            width: 48px;
            animation: rotation-left 1s infinite linear;
        }
    }

    :global(.notifications-container) {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;

        :global(.notification) {
            padding-bottom: 16px;

            :global(.date), span {
                @include font-label-copy-semi-bold();
            }
        }
    }
}

.emptyState {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    height: 100%;

    :global(.title) {
        padding: 16px 0;
        font-size: 26px;
        text-align: center;
    }

    :global(.subtitle) {
        color: $color-tundora;
        width: 250px;
        text-align: center;
    }

    img {
        height: 64px;
    }
}
