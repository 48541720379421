@import "../../../assets/style-params.module";

.bulkEditDateDropdown {
    display: inline-flex;
    align-items: center;
    height: 32px;
    border: 1px solid $color-silver-2;
    background-color: #fff;
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;

    &:global(.disabled) {
        background-color: $color-wild-sand;
        color: $color-dove-grey;
        opacity: 0.2;
        cursor: not-allowed;
    }

    :global(.chevron) {
        margin-left: auto;
        transform: rotate(0);
        transition: transform 300ms ease-in;
    }

    .chevron--open {
        transform: rotate(180deg);
    }
}

.bulkEditDateDropdownPopup {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 280px;
    background-color: #fff;
    padding: 16px;
    z-index: 31;
    box-sizing: border-box;
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
    opacity: 0;
    transform: translateY(-20px);
    transition: all 300ms;
    visibility: hidden;

    :global(.time-options) {
        display: flex;
        flex-direction: column;
        height: 234px;
        overflow-y: scroll;
    }

    :global(.specific-period), :global(.x-to-date-period) {
        padding: 10px 8px;
        margin-top: 3px;
        cursor: pointer;
        user-select: none;
        @include font-label-copy-default();

        &:hover {
            background-color: rgba(41, 171, 226, 0.05);
        }

        &:global(.disabled) {
            color: $color-dove-grey;

            &:hover {
                background-color: initial;
                cursor: not-allowed;
            }
        }
    }

    :global(.information) {
        margin-top: 11px;
        margin-bottom: 8px;
        padding: 0 8px;
        line-height: 16px;
        font-size: 12px;
        color: #656565;
    }

    :global(.submit-changes) {
        margin-top: 8px;
    }
}

.bulkEditDateDropdownPopupOpen {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
