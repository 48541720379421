.MetricPicker {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:global(.grow) {
        flex: 1;
    }

    :global(.search-container-wrapper) {
        width: 100%;
        margin-bottom: 16px;
    }

    :global(.picker-content) {
        flex: 1;
        overflow-y: auto;

        :global(.metrics) {
            margin: 8px 0;
            display: flex;
            flex-direction: column;
        }

        :global(.empty) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
