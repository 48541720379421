@import "../../assets/style-params.module";

body {
    margin: 0 !important;
}

:global(.empty-state) {
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
}

:global(.main-content) {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: $color-wild-sand;
    width: 100%;
}

:global(.is-launchpad-path) {
    display: unset;
}

:global(.d-flex) {
    display: flex;
}

:global(.wrapper) {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-height: 100vh;
}
