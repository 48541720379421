@import "../../../../assets/mixins.module";

.AuthedEntitiesList {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    overflow-y: scroll;
    @include hideScrollbar();
}

.BrandRow {
    display: flex;
    height: 32px;
    line-height: 32px;
    align-items: center;
    justify-content: space-between;

    :global(.row-platform-icons) {
        display: flex;
    }
}

.PlatformIcon {
    height: 24px;
    width: 24px;
}
