@import "../../assets/style-params.module";

.facebook {
    background: url("../../assets/img/platform/facebook_active.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
    background-color: $color-facebook !important;
}

.twitter {
    background: url("../../assets/img/platform/x_active.svg");
    background-color: $color-x !important;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
}

.x {
    background: url("../../assets/img/platform/x_active.svg");
    background-color: $color-x !important;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
}

.youtube {
    background-color: $color-youtube !important;
    background-size: 24px 24px;
    color: white;

    &:not(:global(.hide-icon)) {
        background: url("../../assets/img/platform/youtube_active.svg");
        background-repeat: no-repeat;
        background-position-y: center;
        background-position-x: 6px;
    }
}

.instagram {
    background: url("../../assets/img/platform/instagram_white.svg");
    background-color: $color-instagram !important;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
}

.twitch {
    background: url("../../assets/img/platform/twitch_active.svg");
    background-color: $color-twitch !important;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
}

.tiktok {
    background: url("../../assets/img/platform/tiktok_active.svg");
    background-color: $color-tiktok !important;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
}

.cross_platform {
    background: url("../../assets/img/platform/cross_platform_white.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
    background-color: #404040 !important;
}

.reddit {
    background: url("../../assets/img/platform/reddit_white.svg");
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position-y: center;
    background-position-x: 6px;
    background-color: $color-reddit !important;
}

.groupHeader {
    :global(.ag-header-group-cell) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        border-width: 0 1px 0 1px;
        border-style: solid;
        border-color: $color-wild-sand;

        :global(.ag-header-cell-text) {
            font-family: Arial, sans-serif;
            font-weight: 700;
        }

        > button {
            width: 24px;
            height: 24px;
            margin: 0 0 0 6px;
            border: 0;
            padding: 0;
            cursor: pointer;
            background: $color-wild-sand;
        }
    }
}

.header {
    &:global(.ag-header-cell) {
        width: 100%;
        border-width: 0 1px 1px 1px;
        border-style: solid;
        border-color: $color-wild-sand;
    }

    :global(.ag-cell-label-container) {
        margin-right: 5px !important;
    }
}

.headerExpanded {
    &:global(.ag-header-cell) {
        background: $color-wild-sand;
    }
}
