@import "../../../assets/style-params.module";

.teamTabContainer {
    padding: 32px;
    width: 100%;

    :global(.header-text) {
        font-size: 20px;
        line-height: 24px;
    }

    :global(.team-tab-content) {
        width: 100%;
        display: flex;
        flex-direction: column;

        :global(.error-message) {
            margin-top: 25px;
            text-align: center;
        }

        :global(.placeholder-container) {
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            > div {
                display: flex;
                margin-bottom: 10px;
                justify-content: space-between;
            }

            :global(.image-placeholder) {
                width: 120px;
                height: 20px;
                margin-bottom: 20px;
            }

            :global(.text-placeholder) {
                width: 100%;
                height: 200px;
            }
        }

        :global(.team-tab-not-admin-details) {
            width: 70%;
            margin: 89px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            :global(.team-tab-message) {
                width: 80%;
                margin: auto;
                font-size: 24px;
                line-height: 24px;
                font-weight: 400;
                text-align: center;
            }

            :global(.logo) {
                width: 101px;
                height: 110px;
                margin-bottom: 26px;
            }

            :global(.text) {
                text-align: center;
                font-size: 13px;
                line-height: 16px;
                font-weight: 400;
                margin-top: 8px;
            }
        }

        :global(.team-tab-active) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-top: 25px;

            button {
                background: $color-summer-sky;
                border: none;
                color: $color-white-solid;
                width: 172px;
                height: 32px;
                cursor: pointer;
            }

            :global(.active-members) {
                display: flex;
                align-items: center;
            }
        }

        :global(.team-tab-active-table) {
            margin-top: 40px;
        }
    }
}

.AgGridStyle {
    :global(.ag-root-wrapper-body):global(.ag-layout-normal) {
        :global(.ag-center-cols-viewport) {
            :global(.ag-cell) {
                text-align: left;
            }
        }
    }

    :global(.btn-renderer) {
        border: 1px solid $color-deep-sky-blue;
        color: $color-summer-sky;
        background: $color-white-solid;
        cursor: pointer;
        padding: 6px 10px;
        border-radius: 2px;
        position: absolute;
        top: 20%;

        &:hover {
            background: $color-summer-sky;
            color: $color-white-solid;
        }
    }

    :global(.active-renderer) {
        display: flex;
        align-items: center;

        > span {
            margin-left: 5px;
        }

        :global(.icon) {
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: $color-day-red;

            &:global(.active) {
                background-color: $color-vivid-malachite;
            }
        }
    }

    :global(.package-renderer) {
        :global(.tooltip-render) {
            width: 150px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}

.tooltipBody {
    width: 250px;
    flex-direction: column;
}
