.picker {
    :global(.horizontal-divider):global(.industries) {
        margin-bottom: 0px;
    }

    :global(.checkbox-wrapper) {
        width: 100%;
        display: grid;
        grid-template-columns: 32px auto;
        margin-bottom: 12px;

        > div {
            margin: auto;
        }
    }

    :global(.scroll-wrapper):global(.industries) {
        height: calc(100% - 20px);
        border: 0px;
        margin: 0px 0px -15px 0px;
    }

    :global(.pills) {
        height: initial;
        max-height: 56px;

        :global(.selection) {
            background: #2d2e2f;
            border: 0px;
            border-radius: 12px;
            margin: 0 4px 4px 0;
            overflow: hidden;
            padding: 0 32px 0 8px;
            position: relative;

            :global(.name) {
                color: #fff;
                font-size: 12px;
                line-height: 24px;
            }

            :global(.type) {
                display: none;
            }

            img:global(.close) {
                background: #404040;
                border-radius: 12px;
                position: absolute;
                right: 0px;
            }
        }
    }

    :global(.pills):global(.without-closing) {
        :global(.selection) {
            padding: 0px 8px;
        }
    }

    :global(.bottom) {
        margin-top: 15px;
    }
}

.loadingState {
    height: 454px;
}

.emptyState {
    height: 454px;

    :global(.industries-empty) span {
        padding: 0px 48px;
    }
}
