
.DropdownButtonLabel {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #fff;

    :global(.text) {
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        display: flex;
        align-items: center;

        img {
            margin-right: 2px;
        }
    }

    :global(.option) {
        height: 16px;
        line-height: 16px;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:global(.placeholder) {
            opacity: 0.56;
        }
    }
}
