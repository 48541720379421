.categoryEntityWrapper {
    display: flex;
    align-items: center;
    width: max-content;

    img {
        width: 14px;
        height: 14px;
        margin: 0 2px;
    }
}
