.ClickToShowDashboardsCellRenderer {
    color: #0066cc;
    cursor: pointer;
}

.disabledOption {
    cursor: not-allowed !important;
    display: block;
}

.tooltip {
    width: 150px;
    text-align: center;
}
