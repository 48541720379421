.Card {
    box-sizing: border-box;
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
    width: 250px;

    :global(.toggle-option) {
        display: flex;
        justify-content: space-between;
    }

    :global(.option) {
        padding: 8px;
        line-height: 16px;
        cursor: pointer;

        &:not(:global(.toggle-option)) {
            &:hover {
                background: rgba(41, 171, 226, 0.05);
            }
        }
    }
}
