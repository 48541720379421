@import "../../assets/style-params.module";

.permissionDeniedContainer {
    display: flex;
    flex-direction: column;
    height: 100vh;

    :global(.margined) {
        margin: 56px 16px 16px 16px;
    }

    :global(.content-wrapper) {
        background: white;
        flex: 1;
        margin: 16px;
        margin-top: 0px;

        :global(.content) {
            margin: 0px auto;
            margin-top: 82px;
            max-width: 762px;

            &:after {
                clear: both;
                content: "";
                display: table;
            }

            > img {
                float: left;
                margin-right: 24px;
                width: 250px;
            }

            h1 {
                font-weight: normal;
                font-size: 24px;
                line-height: 24px;
                margin: 0px;
            }

            p {
                color: #303030;
                font-size: 13px;
                line-height: 16px;
                margin: 0px;
                margin-top: 8px;

                &:last-of-type {
                    margin-top: 16px;

                    > a {
                        background: $color-curious-blue;
                        border: 1px solid $color-curious-blue;
                        border-radius: 3px;
                        color: white;
                        display: inline-block;
                        font-weight: $font-label-copy-semi-bold-font-weight;
                        padding: 3px 16px;
                        text-decoration: none;

                        &:active {
                            background: white;
                            color: $color-curious-blue;
                        }

                        &:first-of-type {
                            background: white;
                            color: $color-curious-blue;

                            &:active {
                                background: $color-curious-blue;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }
}
