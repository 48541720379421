@keyframes rotation {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes rotation-left {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(-360deg);
    }
}

@keyframes displayBlockAnimation {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
