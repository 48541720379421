@import "../../../../assets/style-params.module";

.metricCellRenderer {
    display: flex;
    justify-content: right;
    align-items: center;
    height: 100%;

    span {
        width: 100%;
    }

    :global(.blobs) :global(.blob) {
        margin: 4px;
    }

    :global(.blob) {
        background: $color-tundora;
        border-radius: 50%;
        display: inline-block;
        height: 6px;
        margin-left: 5px;
        margin-right: 20px;
        margin-top: 4px;
        vertical-align: top;
        width: 6px;
    }
}

.tooltip {
    :global(.tooltipBody) {
        max-width: 190px;
    }
}
