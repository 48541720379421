@import "../../../assets/style-params.module";

.tabWithDropdown {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    :global(.dropdownButton) {
        transform: rotate(0);
        transition: transform 300ms ease-in;

        &:global(--open) {
            transform: rotate(180deg);
        }
    }

    &:global(.read-only) {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;

        :global(.lock-icon) {
            height: 13px;
            width: 10px;
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%2214%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.75%204.375h-.625v-1.25a3.126%203.126%200%2000-6.25%200v1.25H1.25c-.688%200-1.25.563-1.25%201.25v6.25c0%20.688.563%201.25%201.25%201.25h7.5c.688%200%201.25-.563%201.25-1.25v-6.25c0-.688-.563-1.25-1.25-1.25zM5%2010c-.688%200-1.25-.563-1.25-1.25%200-.688.563-1.25%201.25-1.25.688%200%201.25.563%201.25%201.25C6.25%209.438%205.687%2010%205%2010zm1.938-5.625H3.063v-1.25A1.94%201.94%200%20015%201.187a1.94%201.94%200%20011.938%201.938v1.25z%22%20fill%3D%22%23404040%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
            background-repeat: no-repeat;
            margin-left: 15px;
        }
    }
}

.dropdown {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    position: absolute;
    width: 256px;
    padding: 16px;
    background-color: #fff;
    border: 1px solid $color-alabaster;
    border-radius: 2px;
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
    z-index: 31;
    opacity: 0;
    transform: translateY(-20px);
    transition: all 300ms;

    & > * {
        padding: 8px 0 8px 8px;
        cursor: pointer;
        color: $color-black;

        &:hover {
            background-color: rgba(41, 171, 226, 0.05);
        }
    }
}

.dropdownOpen {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}
