.DraggableModal {
    position: absolute;
    z-index: 999999;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0 16px RGBA(176, 176, 176, 0.5);

    :global(.header) {
        background-color: #001F33;
        border-radius: 4px 4px 0 0;
        color: #fff;
        height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: move;
        padding: 0 8px;

        :global(.header-content) {
            flex: 1;
        }

        :global(.close-icon) {
            cursor: pointer;
        }
    }

    :global(.content) {
        border: 1px solid #d3d3d3;
        width: 464px;
        height: 344px;
        padding: 16px;
        background-color: #fff;
    }
}
