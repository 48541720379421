@import "../../assets/style-params.module";

.PlatformAlert {
    display: block;
    width: 100%;
    padding: 16px;
    background: #404042;

    h2 {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 13px;
        font-weight: normal;
        margin: 0;
        padding: 0;

        .cta {
            background: #ffc600;
            border: 1px solid #ffc600;
            border-radius: 3px;
            color: #000000;
            cursor: pointer;
            display: inline-block;
            font-family: Arial, sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 30px;
            outline: 0 none;
            padding: 0 16px;
            text-decoration: none;

            &--secondary {
                background: transparent;
                color: #ffc600;
            }
        }
    }

    :global(.close-button) {
        position: absolute;
        right: 8px;
        top: 8px;
        cursor: pointer;
        filter: invert(1) hue-rotate(180deg);
    }
}
