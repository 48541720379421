.MetricPickerRowContent {
    display: flex;
    align-items: center;
    width: 100%;

    :global(.tooltip-wrapper) {
        display: flex;
        margin-left: -4px;
    }

    :global(.private-metric-icon) {
        width: 24px;
        height: 24px;
    }

    :global(.search-container-wrapper) {
        width: 530px;
    }

    :global(.metricType) {
        margin-left: auto;
        color: #707070;
    }
}
