@import "../../../assets/style-params.module";

.backgroundSaveStatus {
    color: $color-daintree;
    font-size: 10px;
    height: 16px;
    line-height: 16px;
    letter-spacing: 0.36px;
    user-select: none;

    > img {
        height: 24px;
        position: relative;
        top: -4px;
        vertical-align: top;
        animation-duration: 1s;
        animation-fill-mode: both;
    }

    &:global(.success) {
        > img {
            animation-duration: 2s;
            animation-name: bounceIn;
        }
    }

    &:global(.saving) {
        > img {
            animation-iteration-count: infinite;
            animation-name: spin;
        }
    }

    &:global(.failed) {
        > img {
            animation-iteration-count: infinite;
            animation-name: tada;
        }

        u {
            cursor: pointer;
        }
    }

    @keyframes spin {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes tada {
        from {
            transform: scale3d(1, 1, 1);
        }

        10%, 20% {
            transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
        }

        30%, 50%, 70%, 90% {
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        }

        40%, 60%, 80% {
            transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        }

        to {
            transform: scale3d(1, 1, 1);
        }
    }

    @keyframes bounceIn {
        from, 20%, 40%, 60%, 80%, to {
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        0% {
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }

        20% {
            transform: scale3d(1.1, 1.1, 1.1);
        }

        40% {
            transform: scale3d(0.9, 0.9, 0.9);
        }

        60% {
            opacity: 1;
            transform: scale3d(1.03, 1.03, 1.03);
        }

        80% {
            transform: scale3d(0.97, 0.97, 0.97);
        }

        to {
            opacity: 1;
            transform: scale3d(1, 1, 1);
        }
    }
}
