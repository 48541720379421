@import "../../../../assets/style-params.module";

.CategoryRow {
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
    align-items: center;

    :global(.left) {
        flex: 1;
        height: 24px;
        width: 24px;
        display: flex;

        :global(.name) {
            display: flex;
            align-items: center;
            overflow: hidden;

            > p {
                overflow: hidden;
                text-overflow: ellipsis;
                margin: 0;
                white-space: nowrap;
                line-height: 24px;
            }
        }
    }

    :global(.right) {
        display: flex;
        margin-left: 8px;

        :global(.shared) {
            color: $color-silver;
            margin-right: 8px;
        }

        :global(.controller) {
            color: $color-mostly-pure-cyan;
            cursor: pointer;
            display: flex;
            justify-content: right;
            align-items: center;
            width: 30px;
        }
    }
}
