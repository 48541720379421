.modalCard {
    box-shadow: 0px 2px 4px 0px rgba(64, 64, 66, 0.5);
    padding: 23px;
}

.cardHeader {
    margin-bottom: 24px;

    > h3 {
        font-family: Arial, sans-serif;
        font-size: 24px;
    }
}
