.connectSocialAccountsCardContentResult {
    display: flex;

    :global(.content-card) {
        border: none;
        padding: 0px;
        padding-right: 8px;
    }

    :global(.content-card-header) {
        font-size: 20px;
    }

    b {
        display: block;
        margin-bottom: 8px;
    }
}
