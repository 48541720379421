.enter {
    opacity: 0;
}

.enterActive {
    opacity: 1;
    transition: opacity 300ms;
}

.enterDone {
    opacity: 1;
}

.exit {
    opacity: 1;
}

.exitActive {
    opacity: 0;
    transition: opacity 300ms;
}

.exitDone {
    opacity: 0;
}
