@import "../../../../assets/style-params.module";

.shareDashboard {
    color: #fff;
    background: #067D85;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-top: 16px;
    border-radius: 4px;
}

.buttonDeny {
    color: #fff;
    margin-left: 8px;
    background-color: transparent;
    border: 1px solid #fff;

    &:hover {
        background-color: transparent;
    }
}

.buttonAccept {
    color: #000;
    margin-left: 8px;
    border: 1px solid #fff;
    background: #fff;

    &:hover {
        color: #000;
        background: #fff;
    }
}
