@import "../../../../../assets/style-params.module";
@import "../../../../../assets/mixins.module";

.addPropertiesTab {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    :global(.search-box) {
        margin-top: 16px;
    }

    :global(.spacer) {
        margin: 15px 0;
        border-bottom: 1px solid #E0DADE;
    }

    :global(.properties-list) {
        border-top: solid 1px #E0DADE;
        padding: 15px 0;
        margin-top: 15px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        flex: 1;
        @include hideScrollbar();

        :global(.end-of-results) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    :global(.limit-warning) {
        background-color: rgba(41, 171, 226, 0.05);
        padding: 16px;
        margin-top: 15px;

        span {
            color: $color-black;
            line-height: 16px;

            b {
                color: $color-curious-blue;
            }
        }

        &:global(.exceeded) {
            background-color: $color-beige;

            span {
                color: #1D1D1D;
            }
        }
    }

    :global(.request-note) {
        padding: 16px;
        margin-top: 15px;
        background-color: $color-conifer;

        &:global(.error) {
            background-color: #ffe3e3;
        }
    }
}
