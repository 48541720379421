.enter {
    opacity: 0;
}

.enterActive {
    opacity: 1;
    transition: opacity 300ms;
    animation-name: open;
    animation-duration: 300ms;
    animation-play-state: running;
}

.enterDone {
    opacity: 1;
}

.exit {
    opacity: 1;
}

.exitActive {
    opacity: 0;
    transition: opacity 300ms;
    animation-name: close;
    animation-duration: 300ms;
    animation-play-state: running;
}

.exitDone {
    opacity: 0;
}

@keyframes open {
    0% {
        transform: scale3d(1.1, 1.1, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes close {
    0% {
        transform: scale3d(1, 1, 1);
    }
    100% {
        transform: scale3d(0.9, 0.9, 1);
    }
}
