@import "../../../assets/style-params.module";

.insightStudioDashboardSelection {
    margin-top: 16px;
    min-height: 353px;
    --ag-row-group-indent-size: 16px;

    :global(.ag-row-level-0) {
        --ag-row-group-indent-size: 0;
    }

    :global(.emptyBox) {
        width: 314px;
        height: 116px;
        padding: 119px 0;
        margin: auto;
        display: grid;

        :global(.emptyBoxMessage) {
            font-size: 16px;
            text-align: center;
            margin: auto;
        }
    }

    table {
        width: 100%;

        tr {
            :global(.cell) {
                width: 100%;
                height: 32px;
                margin-bottom: 8px;
            }

            td {
                &:not(:first-child) {
                    width: 232px;
                }
            }
        }
    }

    :global(.pointer) {
        cursor: pointer;
    }

    :global(.header-folder) {
        :global(.ag-group-value) {
            display: flex;
            align-items: center;

            &:before {
                height: 24px;
                margin-right: 16px;
                content: url("../../../assets/img/v2/folder.svg");
            }
        }
    }

    :global(.ag-icon-tree-closed), :global(.ag-icon-tree-open) {
        display: flex;
        align-items: center;

        &:before {
            height: 24px;
            margin-right: 24px;
        }
    }

    :global(.ag-icon-tree-closed) {
        &:before {
            content: url("../../../assets/img/v2/chevron_right.svg");
        }

        &:after {
            content: url("../../../assets/img/v2/folder.svg");
        }
    }

    :global(.ag-icon-tree-open) {
        &:before {
            content: url("../../../assets/img/v2/chevron_down.svg");
        }

        &:after {
            content: url("../../../assets/img/v2/folderOpened.svg");
        }
    }

    :global(.ag-cell) {
        border: none !important;
    }

    :global(.ag-selection-checkbox) {
        &:global(.ag-invisible) {
            visibility: visible !important;
            background: transparent url("../../../assets/img/checkbox_disabled.svg") center no-repeat;
            height: 16px;
            cursor: not-allowed;
            :global(.ag-checkbox) {
                visibility: hidden;
            }
        }
        :global(.ag-checkbox) :global(.ag-checkbox-input-wrapper) {
            &::after{ //let's preload checkbox_checked_black so it doesn't flicker after checking
                position: absolute;
                width: 0px;
                height: 0px;
                overflow: hidden;
                content: url("../../../assets/img/checkbox_checked_black.svg");
            }
            background: transparent url("../../../assets/img/checkbox_black.svg") center no-repeat;

            &:global(.ag-checked) {
                background: transparent url("../../../assets/img/checkbox_checked_black.svg") center no-repeat;
            }

            &:global(.ag-indeterminate) {
                background: transparent url("../../../assets/img/checkbox_mixed_black.svg") center no-repeat;
            }

            input {
                width: 16px;
                height: 16px;
                cursor: pointer;
                opacity: 0;
            }
        }
    }

    :global(.ag-cell-wrapper) {
        display: flex !important;
        align-items: center;
        gap: 8px;
    }

    :global(.checkbox-cell) {
        :global(.ag-cell-wrapper) {
            width: 16px !important;
        }
    }

    :global(.ag-pinned-left-header), :global(.ag-pinned-left-cols-container) {
        box-shadow: none !important;
    }

    :global(.ag-row):global(.ag-row-selected):global(.ag-row):global(.ag-row-even) {
        background: white;
    }

    :global(.ag-row):global(.ag-row-selected):global(.ag-row):global(.ag-row-odd) {
        background: $color-wild-sand;
    }

    :global(.ag-cell-label-container) {
        padding: 0;
    }

    :global(.ag-header-cell) {
        padding: 7px;
    }

    :global(.ag-center-cols-clipper), :global(.ag-header-viewport) {
        &:after {
            box-shadow: none;
        }
    }

    :global(.ag-center-cols-viewport) {
        :global(.ag-cell):global(.name-cell) {
            cursor: pointer;
            text-align: left;
            font-weight: 600;

            div:global(.ag-react-container) {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:hover {
                    overflow: visible;
                }
            }
        }

        :global(.ag-cell):global(.owner-cell) > div {
            display: flex !important;
            justify-content: space-between;
        }

        :global(.ag-cell):global(.type-cell) > div {
            display: flex !important;
            justify-content: space-between;
        }
    }

    :global(.selections) {
        position: relative;

        & > :global(.read-only):after {
            content: "";
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.7);
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:global(.read-only):after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            content: "";
            width: 42px;
            height: 42px;
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2242%22%20height%3D%2242%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Ccircle%20fill%3D%22%23002032%22%20cx%3D%2221%22%20cy%3D%2221%22%20r%3D%2221%22%2F%3E%3Cpath%20d%3D%22M17.25%2018.375h.625v-1.25a3.126%203.126%200%20016.25%200v1.25h.625c.688%200%201.25.563%201.25%201.25v6.25c0%20.688-.563%201.25-1.25%201.25h-7.5c-.688%200-1.25-.563-1.25-1.25v-6.25c0-.688.563-1.25%201.25-1.25zM21%2024c.688%200%201.25-.563%201.25-1.25%200-.688-.563-1.25-1.25-1.25-.688%200-1.25.563-1.25%201.25%200%20.688.563%201.25%201.25%201.25zm-1.938-5.625h3.875v-1.25A1.94%201.94%200%200021%2015.187a1.94%201.94%200%2000-1.938%201.938v1.25z%22%20fill%3D%22%23FFF%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
        }
    }
}

.title {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto auto auto auto;
    column-gap: 8px;

    > span {
        margin: auto 0;
    }
}
