.tree {
    padding: 0;
    margin: 0;
    list-style: none;

    :global(.branch) {
        display: none;
        padding: 0;
        margin: 2px 0 4px 28px;
        list-style: none;

        li:first-child {
            & > :global(.item):before {
                height: 16px;
                margin-top: -2px;
            }
        }
    }

    li {
        &:global(.open) {
            &:not(:last-child) {
                :global(.branch) {
                    position: relative;

                    &:before {
                        content: "";
                        display: block;
                        border-left: 1.5px dotted #cbcbcb;
                        position: absolute;
                        top: 0;
                        bottom: 20px;
                        left: 0;
                    }
                }
            }

            > :global(.item) :global(.open-btn) {
                transform: rotateZ(0deg);
            }

            > :global(.branch) {
                display: block;
            }
        }
    }

    > li {
        > :global(.item):before {
            content: none;
        }

        > :global(.branch) {
            margin-left: 11px;

            > li:global(.open):not(:last-child) {
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    border-left: 1.5px dotted #cbcbcb;
                    position: absolute;
                    top: 0;
                    bottom: 20px;
                    left: 0;
                }
            }
        }

        &:global(.open):not(:last-child) {
            & > :global(.branch):before {
                content: none;
            }
        }
    }

    :global(.item) {
        display: flex;
        align-items: flex-start;

        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 32px;
            border: 1.5px dotted #cbcbcb;
            border-top: 0;
            border-right: 0;
            flex: 0 0 auto;
            margin-top: -18px;
        }

        :global(.entity) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        :global(.open-btn) {
            margin-top: 4px;
            cursor: pointer;
            transform: rotateZ(-90deg);
            transition: transform 0.3s ease;

            & + :global(.option) {
                margin-left: 0;
            }
        }

        :global(.option) {
            flex: 1 1 auto;
            margin-left: 24px;
        }

        :global(.id) {
            color: #707070;
            height: 32px;
            display: flex;
            align-items: center;
        }
    }

    :global(.break-down) {
        display: flex;
        justify-content: center;
        align-items: center;

        :global(.info) {
            display: flex;
            justify-content: center;
            align-items: center;

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
}
