@import "../../../../assets/style-params.module";

.specificPeriodModal {
    z-index: 3000;

    :global(.content) {
        :global(.calendar-field) {
            border: 1px solid #C9C8C8;
            border-radius: 3px;
            padding: 8px;
            width: 643px;

            :global(.DayPicker__hidden) {
                visibility: visible;
            }
        }
    }
}
