@import "../../../../assets/style-params.module";

.NameAvailabilityPicker {
    display: flex;

    :global(.picker) {
        display: flex;
        flex-direction: column;

        &:global(.name) {
            flex: 2;

            :global(.input) {
                display: flex;
                height: 32px;

                :global(.input-text) {
                    padding-left: 8px;
                    flex: 1;
                    border: 1px solid $color-dove-grey;
                    border-top-left-radius: 2px;
                    border-bottom-left-radius: 2px;
                }
            }
        }

        &:global(.availability) {
            flex: 1;

            :global(.availability-picker) {
                display: flex;

                > :global(.dropdown) {
                    display: flex;
                    flex: 1;
                    border: 1px solid $color-dove-grey;
                    border-left: 0;
                    border-radius: 0;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                    width: 100%;
                }
            }
        }

        :global(.label) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 24px;

            > label {
                @include font-label-copy-bold();
            }
        }
    }
}

.pickerDropdown {
    width: 188px;
}
