.propertyCell {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    align-items: center;
    padding: 0 16px;

    img {
        display: block;
        height: auto;
        width: 16px;
        filter: brightness(0.1);
    }

    :global(.cell-value) {
        padding: 0 16px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
