.optionSelector {
    position: relative;
    font-family: Arial, sans-serif;
    user-select: none;

    :global(.label) {
        height: 16px;
        color: #000000;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;

        :global(.circle) {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #29ABE2;
            display: inline-block;
            margin-bottom: 2px;

            &:global(.property) {
                background-color: #9CD358;
            }

            &:global(.big) {
                width: 16px;
                height: 16px;
            }
        }
    }

    img {
        vertical-align: top;
        transition: transform 300ms ease-in;

        &:global(.rotated) {
            transform: rotate(180deg);
        }
    }

    :global(.control) {
        cursor: pointer;
        display: inline-block;

        span {
            border-bottom: 2px solid #F6F6F6;
            display: inline-block;
            height: 22px;
            line-height: 22px;
            margin-left: 16px;

            &:global(.disabled) {
                cursor: not-allowed;
            }

            img {
                margin-left: 2px;
                position: relative;
                top: 5px;
                width: 16px;
            }
        }
    }

    :global(.highlight) {
        height: 48px;
        width: 177px;
        background-color: #f6f6f6;
        padding-top: 8px;

        :global(.options) {
            height: 16px;
            width: 131.8px;
            color: #303030;
            color: #000000;
            font-size: 13px;
            line-height: 16px;
        }

        :global(.choice) {
            width: 132px;
            display: inline-block;
            line-height: 16px;
            overflow: hidden;
            text-overflow: clip;
            height: 16px;
            margin-top: 4px;
        }
    }
}

.dropdown {
    background-color: #FFFFFF;
    border: 1px solid #F8F8F8;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0px rgba(64, 64, 66, 0.5);
    padding: 15px 16px 16px 16px;
    position: absolute;
    width: 432px;
    max-height: 352px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-20px);
    transition: all 300ms;
    overflow: hidden;

    &:global(.open) {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    :global(.dropdownHeaderText) {
        margin-bottom: 8px;
        line-height: 16px;
    }

    :global(.dropdownFooterText) {
        margin-top: 8px;
        line-height: 16px;
    }

    :global(.topBar) {
        display: flex;
        justify-content: space-between;

        :global(.clearSelection) {
            color: #29ABE2;
            cursor: pointer;
        }
    }

    :global(.horizontal-divider) {
        height: 1px;
        width: 432px;
        background-color: #E2E4E6;
        margin-top: 15px;
    }

    :global(.options) {
        margin-top: 15px;
        box-sizing: content-box;
        overflow-y: scroll;
        max-height: 281px;
        scrollbar-color: #f6f6f6 #fff;

        :global(.circle) {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #29ABE2;
            display: inline-block;
            margin-bottom: 2px;

            &:global(.property) {
                background-color: #9CD358;
            }

            &:global(.big) {
                width: 16px;
                height: 16px;
            }
        }

        :global(.option) {
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            padding: 8px;
            color: #303030;
            font-size: 13px;
            line-height: 18px;
            height: 32px;

            &:hover, &:global(.focused) {
                background-color: rgba(41, 171, 226, 0.05);
            }

            &:global(.autoHeight):hover {
                background-color: unset;
            }

            :global(.separated) {
                margin-right: 8px;
            }

            :global(.circle) {
                position: relative;
                top: 5px;

                &:global(.separated) {
                    margin-right: 18px;
                }

                &:global(.big) {
                    margin-bottom: 0px;
                    top: 0px;
                }

                &:global(.subproperty) {
                    margin-left: 3px;
                    margin-right: 25px;
                }
            }

            &:global(.name) {
                font-weight: 600;
                height: auto;
            }

            &:global(.addAll) {
                padding-left: 32px;
            }

            &:global(.viewAll) {
                padding-left: 28px;
                color: #29ABE2;

                img {
                    margin-right: 15px;
                    transform: rotate(-90deg);

                    &:global(.rotated) {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        :global(.name-wrapper) {
            display: flex;
            position: relative;

            :global(.connector) {
                width: 20px;
                border: 1px dashed #cbcbcb;
                border-right: none;
                border-top: none;
                position: absolute;
                left: -46px;
                height: calc(100% + 18px);
                top: 6px;
                z-index: -1;
            }
        }

        :global(.option):global(.isHeader) {
            font-weight: 600;
            background-color: #f6f6f6;
            cursor: initial;
        }

        :global(.option):global(.hasIcon) {
            position: relative;
            padding-left: 32px;
            line-height: 20px;

            img {
                position: absolute;
                top: 4px;
                left: 4px;
            }
        }

        :global(.option):global(.loading) {
            color: black;

            img {
                margin-top: -6px;
                width: 24px;
                height: 24px;
            }
        }

        :global(.autoHeight) {
            position: relative;
        }
    }

    :global(.options)::-webkit-scrollbar {
        display: none;
    }

    :global(.label) {
        height: 16px;
        color: #000000;
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
    }
}

.popup {
    :global(.bthlabs-rcp-overlay) {
        z-index: 2000;
    }

    :global(.bthlabs-rcp-inner) {
        z-index: 2001;
    }

    &:global(.underHeader) {
        :global(.bthlabs-rcp-overlay) {
            z-index: 98;
        }

        :global(.bthlabs-rcp-inner) {
            z-index: 99;
        }
    }

    &:global(.fixed) {
        :global(.bthlabs-rcp-inner) {
            position: fixed;
        }
    }
}
