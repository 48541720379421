@import "../../../../assets/style-params.module";

.connectSocialAccountsCardContent {
    display: flex;
    flex-direction: column;
    flex: 1;

    :global(.connect-social-button) {
        margin: 8px 0px;
        width: 100%;
        padding: 0px;
        border-color: $color-dove-grey;

        :global(.connect-social-button-content) {
            height: 48px;
            display: flex;
            justify-content: left;
            flex: 1;

            img {
                padding: 8px;
            }

            span {
                @include font-body-copy();
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-transform: capitalize;
            }
        }
    }

    :global(.loader-container) {
        align-items: center;
        display: flex;
        justify-content: center;
        flex: 1;

        :global(.loader) {
            height: 48px;
            width: 48px;
            animation: rotation-left 1s infinite linear;
        }
    }
}
