.ButtonDownload {
    background: #ffffff;
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 4px 8px;
    border-radius: 16px;

    &:hover {
        stroke: #29abe2;
    }
}
