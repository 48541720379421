.industryCategory {
    display: flex;
    line-height: 16px;
    position: relative;

    img {
        margin-top: -4px;
    }

    &:global(.has-blob) {
        padding-left: 15px;

        &:before {
            border-radius: 50%;
            content: "";
            display: block;
            height: 6px;
            left: 1px;
            position: absolute;
            top: 5px;
            width: 6px;
        }

        &:global(.blob-category) {
            &:before {
                background: #29abe2;
            }
        }

        &:global(.blob-property) {
            &:before {
                background: #9cd358;
            }
        }
    }

    span {
        vertical-align: top;
    }

    :global(.content-shorthand) {
        > img {
            margin: 0px 4px;
            margin-top: -2px;
            vertical-align: top;
        }
    }
}

.popup {
    :global(.bthlabs-rcp-inner) {
        z-index: 100;
    }
}

.tooltip {
    :global(.tooltipBody) {
        max-width: 190px;
        margin-left: 100px;
    }
}
