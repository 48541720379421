.conditionalNotifications {
    position: absolute;
    right: 30px;
    top: 14px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex-shrink: 1;
}
