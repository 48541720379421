.DropdownButton {
    :global(.label-prefixed) {
        margin-left: 4px;
        font-weight: bold;
    }

    :global(.clear-padding) {
        padding: 0 0 0 0;
    }
}
