@import "../../assets/style-params.module";

.mainMenu {
    background-color: #252B39;
    width: 100vw;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    :global(.navbar-left) {
        display: flex;
        width: inherit;

        :global(.logo) {
            padding: 0;
            display: flex;
            align-items: center;

            &:after {
                content: "Comscore";
                height: 40px;
                line-height: 40px;
                padding: 0 24px 0 0;
                margin: 0 0 0 8px;
            }

            > img {
                width: 48px;
            }

            > span {
                margin-left: 8px;
            }
        }
    }

    :global(.navbar-right) {
        display: flex;
    }

    :global(.grouped-icons) {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 0 8px;

        @media screen and (max-width: 1280px) {
            :global(.help-dropdown) {
                :global(.dropdown) {
                    right: 86px;
                    top: 86px;
                }
            }
        }
    }
    
    :global(.icon-nav) {
        display: none;
      }
      
      :global(.icon-hamburger) {
        display: flex;
      }

    :global(.sidebar-underlay) {
        position: absolute;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: 988;
    }

    :global(.hamburger-container), :global(.current-menu) {
        display: none;
    }

    @media screen and (max-width: 1280px) {
        :global(.hamburger-container) {
            width: 40px;
            height: 40px;
            display: block;
            cursor: pointer;

            :global(.bar1), :global(.bar2), :global(.bar3) {
                width: 35px;
                height: 4px;
                background-color: $color-white-solid;
                border-radius: 4px;
                margin: 6px 0;
                transition: 0.4s;
            }

            &:global(.active) :global(.bar1) {
                transform: rotate(-45deg) translate(-8px, 9px);
            }

            &:global(.active) :global(.bar2) {
                opacity: 0;
            }

            &:global(.active) :global(.bar3) {
                transform: rotate(45deg) translate(-5px, -6px);
            }
        }

        :global(.icon-nav) {
            display: flex;
          }
        
        :global(.icon-hamburger) {
            display: none;
          }

        :global(.current-menu) {
            display: inline;
        }
    }

    :global(.button) {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:global(.dot) {
            position: relative;

            &:after {
                position: absolute;
                left: 13px;
                transform: translate(1px, -10px);
                content: "\25CF";
                color: #FF0000;
            }
        }
    }

    :global(.list-notifications-popup) {
        position: absolute;
        bottom: 100px;
        right: 16px;
        z-index: 7;
        display: flex;
        padding: 8px;
        flex-direction: column;

        > div {
            margin-top: 8px;
        }

        > div:first-child {
            margin-top: 0px;
        }
    }   
}
