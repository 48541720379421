@import "../../../assets/style-params.module";

@mixin button-styles() {
    font-family: Arial, sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
}

.container {
    align-items: center;
    background: #067D85;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 16px;
    margin-bottom: 16px;
    margin-top: 16px;
    position: relative;
    border-radius: 4px;
    height: 64px;

    :global(.content) {
        display: flex;
        flex-direction: column;
        color: #FFFFFF;
        font-family: Arial, sans-serif;
    }

    :global(.content__title) {
        width: 100%;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 24px;
        margin-bottom: 8px;
    }

    :global(.content__message) {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 16px;
    }

    :global(.cta) {
        position: absolute;
        bottom: 16px;
        right: 16px;

        :global(.button__learn-more) {
            width: 96px;
            height: 32px;
            border: 1px solid #FFFFFF;
            border-radius: 2px;
            background-color: transparent;
            margin-right: 10px;
            @include button-styles();
            color: #FFFFFF;
        }

        :global(.button__request-access) {
            width: 128px;
            height: 32px;
            border: 1px solid #F8F8F8;
            background-color: #FFFFFF;
            color: #000000;
            @include button-styles();
        }
    }
}
