.chartTooltip {
    background: #404040;
    border-radius: 2px;
    color: white;
    display: block;
    line-height: 24px;
    padding: 8px;

    > img {
        margin-right: 8px;
        vertical-align: top;
    }
}
