.rowOptionsRenderer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
}

.button {
    stroke: #404040;
    cursor: pointer;
    transform: scale(0.8);

    &:hover {
        stroke: #29abe2;
    }
}

.optionsButton {
    border: none;
    background: none;
    padding-left: 0;

    &:active {
        background: none;
        color: inherit;
    }
}

.optionsDropdownContentWrapper {
    margin-top: -8px;
    margin-left: -10px;
}

.optionsDropdownContent {
    min-width: 460px;

    :global(.option) {
        padding: 8px;
        line-height: 16px;
        cursor: pointer;

        &:hover {
            background: rgba(41, 171, 226, 0.05);
        }
    }
}
