.closeButton {
    color: #303030;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    max-width: 90px;

    img {
        height: 24px;
        vertical-align: top;
    }
}
