.ShowMoreContainer {
    width: 100%;
    box-sizing: border-box;
    height: 54px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;

    > img {
        margin-left: 12px;
        margin-right: 12px;
    }
}
