@import "../../assets/style-params.module";

.contentTypeLabelRightText {
    @include font-label-copy-grey-centered();
    font-size: 12px;
}

.contentTypesPicker {
    :global(.dropdown) {
        width: 192px;

        :global(.options) {
            margin-top: 0px;
        }
    }
}

.contentTypesPickerDropdown {
    :global(.abstract-picker-content) {
        :global(.options) {
            margin-top: 0px;
            max-height: 352px;
        }
    }
}

.contentTypeLabelText {
    flex: 1;
}

.contentTypeLabelIcon {
    position: relative;
    top: -4px;
    vertical-align: top;
}
