@import "../../../../../assets/style-params.module";
@import "../../../../../assets/mixins.module";

.addedPropertiesTab {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    :global(.remove-button) {
        width: 92px;
    }

    :global(.search-box) {
        margin-top: 16px;
    }

    :global(.select-all-remove) {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        :global(.select-all) {
            span {
                margin-left: 8px;
            }
        }
    }

    :global(.spacer) {
        margin: 15px 0;
        border-bottom: 1px solid $color-silver-2;
    }

    :global(.listed-properties-scroll-wrapper) {
        overflow-y: auto;
        height: 100%;
        @include hideScrollbar();

        :global(.listed-property) {
            display: flex;
            align-items: center;
            padding: 8px 0;
            @include listElementHover();

            & > *:first-child {
                margin-right: 8px;
            }
        }
    }
}
