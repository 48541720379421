@import "../../assets/style-params.module";

.treeSelect {
    display: flex;
    flex-direction: column;

    img:global(.icon) {
        transition: 0.1s;
    }

    img:global(.icon):global(.open) {
        transform: rotateZ(0);
    }

    img:global(.icon) {
        cursor: pointer;
        transform: rotateZ(-90deg);
    }

    :global(.label) {
        padding: 0 8px;
    }

    :global(.disabled-label) {
        padding: 8px;
    }

    :global(.dot) {
        width: 6px;
        height: 6px;
        margin-right: 9px;
    }

    :global(.option) {
        width: 100%;
        user-select: none;
    }

    :global(.linkBorder) {
        width: 16px;
        display: flex;
        flex-direction: column;
        margin-top: 1.5px;
        min-width: 16px;

        :global(.topBorder) {
            border-left: 1.5px dotted #cbcbcb;
            border-bottom: 1.5px dotted #cbcbcb;
            width: 50%;
            flex: 1;
        }

        :global(.bottomBorder) {
            flex: 1;
            margin-top: 1.5px;
        }
    }

    :global(.head) {
        min-height: 34px;
        display: flex;
        align-items: center;
        position: relative;
        right: 1.5px;
        padding-bottom: 4px;

        :global(.loader-icon) {
            animation: rotation 2s infinite linear;
        }

        :global(.option) {
            display: flex;
            align-items: center;
            min-height: 32px;
            height: max-content;
            overflow-wrap: anywhere;
            max-width: 100%;
            overflow: hidden;
        }

        :global(.label) {
            width: 100%;
        }

        :global(.category-option) {
            width: 100%;
            margin-left: auto;
        }

        :global(.edit-category) {
            cursor: pointer;

            img {
                width: 24px;
                padding-right: 7px;
            }
        }

        :global(.edit-form) {
            display: flex;
            width: 100%;

            > * {
                margin: 0 4px;
            }

            input {
                background: #fff;
                display: inline-block;
                font-size: 13px;
                line-height: 16px;
                outline: 0px;
                padding: 8px;
                border: 1px solid #cfcfcf;
                border-radius: 2px;
                color: #303030;
                flex: 1;
                width: 100%;
            }
        }

        :global(.checkbox-mixed) {
            img {
                background-image: url("../../assets/img/checkbox_mixed_black.svg");
            }
        }
    }

    :global(.branches) {
        display: flex;
        flex-direction: column;

        :global(.branch) {
            margin-left: 38px;
            display: flex;

            &:not(:last-child) :global(.bottomBorder) {
                border-left: 1.5px dotted #cbcbcb;
            }

            & > img {
                margin-right: 7px;
            }

            :global(.link) {
                display: flex;
                align-items: center;
                position: relative;
                justify-content: space-between;
                width: 100%;
                margin-right: 8px;

                :global(.option) {
                    padding-right: 20px;
                    align-items: center;
                }

                &:hover :global(.delete-property) {
                    display: block;
                }

                :global(.delete-property) {
                    display: none;
                    position: absolute;
                    right: 10px;
                    cursor: pointer;
                    height: 24px;
                }

                :global(.description) {
                    color: #707070;
                }
            }
        }
    }
}
