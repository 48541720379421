.OptionSection {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;

    label {
        font-weight: bold;
        padding: 8px;
    }
}

.EmptyInfo {
    padding: 8px;
}
