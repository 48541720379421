@import "../../assets/style-params.module";

.dropdownButton {
    display: flex;
    height: 32px;
    justify-content: space-between;
    padding: 0 0 0 8px;
    border-color: #c9c8c8;
    font-size: $font-label-copy-default-font-size;
    line-height: $font-label-copy-default-line-height;
    text-align: $font-label-copy-default-text-align;
    color: $font-label-copy-default-color;
    font-style: $font-label-copy-default-font-style;
    font-weight: $font-label-copy-default-font-weight;
    font-family: $font-label-copy-default-font-family;

    > img:global(.chevron) {
        height: 24px;
        transition: transform 300ms ease-in;
        width: 24px;
    }

    > div:global(.clear) {
        display: flex;
        margin-left: auto;
    }

    &:global(.open) {
        > img:global(.chevron) {
            transform: rotate(180deg);
        }
    }

    &:active {
        background: white;
        color: $color-curious-blue;
    }

    &:global(.primary) {
        &:active {
            background: $color-curious-blue;
            color: white;
        }
    }

    :global(.children-wrapper) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: flex;
        align-items: center;
    }

    :global(.dropdown-button-icon) {
        transition: none;
        transform: rotate(0deg);
    }
}

.error {
    background-color: $color-lavender-blush;
    border-color: $color-red;
    color: $color-red;

    &:active {
        background: $color-lavender-blush;
        color: $color-red;
    }
}
