.container {
    padding: 16px;
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    :global(.search-container-wrapper) {
        width: 100%;
        margin: 0 0 16px 0;
    }

    :global(.picker) {
        padding: 0;
    }
}
