.MetricPickerOption {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    cursor: pointer;
    padding: 4px 0;
    padding-left: 4px;
    user-select: none;

    &:hover {
        background: rgba(41, 171, 226, 0.05);
    }

    &:global(.section-header) {
        padding: 4px 0;
        background: #f6f6f6;
        cursor: unset;
        margin-bottom: 4px;

        > img {
            margin-right: 4px;
        }

        &:hover {
            background: #f6f6f6;
        }
    }
}
