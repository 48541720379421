@import "../../../../../assets/style-params.module";

.dropdownContent {
    background: $color-white-solid;
    box-shadow: 0 2px 6px 3px rgba(74, 74, 74, 0.2);
    width: 464px;
    height: 400px;
    display: flex;
    flex-direction: column;

    :global(.age-gender-options) {
        overflow-y: auto;
        height: 338px;

        > div:global(.label) {
            font-weight: bold;
        }
    }

    :global(.options) {
        &:global(.gender) {
            margin-top: 0;
        }

        &:global(.age) {
            margin-top: 16px;
        }

        &:global(.hohAge) {
            margin-top: 16px;
            margin-bottom: 16px;
        }

        :global(.option) {
            > div {
                padding-right: 16px;
            }
        }

        > div:global(.listitem) {
            padding: 8px 8px 8px 0;
            position: relative;

            :global(.option):global(.checkbox) {
                margin-left: 30px;
            }
        }

        :global(.ladder) {
            width: 11px;
            height: 14px;
            border-left: 1px dashed $color-silver-light-secondary;
            border-bottom: 1px dashed $color-silver-light-secondary;
            position: absolute;
            top: 4px;
            left: 8px;
        }
    }

    :global(.entities-pills) {
        height: 50px;
        margin-top: 14px;
        border-top: 1px solid #f6f6f6;
        padding-top: 16px;

        :global(.selection) {
            background-color: #bce6f8;
            border: none;
            border-radius: 4px;
            padding: 6px;

            :global(.name) {
                font-weight: 400;
                font-size: 12px;
            }
        }
    }

    :global(.btns-group) {
        display: flex;
        justify-content: flex-end;
        padding-top: 16px;
        border-top: 1px solid $color-silver-light-third;

        > button:first-of-type {
            margin-right: 8px;
        }
    }
}

.dropdownContentNoPills {
    :global(.age-gender-options) {
        height: 420px;
    }
}
