.tvDropdown {
    :global(.open) {
        border: 1px solid #000000;
    }
}

.tvDatePicker {
    :global(.react-datepicker-ignore-onclickoutside) {
        :global(button) {
            border: 1px solid #000000;
        }
    }
}