.RenderTree {
    margin-left: 22px;
    position: relative;

    :global(.button) {
        position: relative;
        padding-top: 10px;
    }

    :global(.node) {
        position: relative;
        margin-left: 20px;

        :global(.leaf) {
            position: relative;
            margin-left: -13px;

            &:before {
                content: "";
                position: absolute;
                top: -8px;
                left: 0;
                height: 100%;
                border-left: 1.5px dotted #cbcbcb;
            }

            &:first-child:before {
                top: 8px;
            }
        }

        :global(.leaf)::after {
            content: "";
            position: absolute;
            top: 19px;
            left: 0;
            width: 15px;
            border-bottom: 1.5px dotted #cbcbcb;
        }

        :global(.single-leaf) {
            &:before {
                height: 43%;
            }
        }
    }
}

.CategoriesContainer {
    :global(.section):not(:first-child) {
        padding-top: 12px;
    }

    :global(.section) {
        :global(.category-label) {
            font-weight: bold;
        }
    }

    :global(.loading-state) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 4px;

        :global(.placeholder) {
            width: 100%;
            height: 22px;

            &:not(:first-of-type) {
                margin-top: 8px;
            }
        }
    }
}
