@import "../../assets/style-params.module";

.SearchBox {
    display: flex;
    margin-top: 8px;
    width: 432px;

    :global(.search-container) {
        position: relative;
        flex: 1;
        display: flex;

        input {
            background: white;
            display: inline-block;
            font-size: 13px;
            line-height: 16px;
            outline: 0px;
            padding: 8px 32px;
            border: 1px solid #CFCFCF;
            border-radius: 2px;
            color: #303030;
            flex: 1;

            &:global(.placeholder) {
                color: #707070;
                font-size: 13px;
            }
        }

        :global(.icon) {
            position: absolute;
            transform: none;

            &:global(.search) {
                top: 6px;
                left: 8px;
                width: 22px;
                height: 22px;
            }

            &:global(.clear) {
                top: 5px;
                right: 6px;
                cursor: pointer;
                pointer-events: all;
            }

            &:global(.rotating) {
                animation: rotation-left 2s infinite linear;
            }
        }
    }
}
