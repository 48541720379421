.SearchBoxContainer {
    display: flex;
    gap: 10px;
}

.MultiChoiceList {
    width: 170px;
    border-radius: 2px;
    box-shadow: 0 2px 4px 0px rgba(64, 64, 66, 0.5);
}

.MultiChoiceContainer {
    position: relative;
    margin-top: 4px;

    :global(.option) {
        padding: 8px;

        :global(.separated) {
            margin-right: 8px;
        }
    }
}

.ButtonContainer {
    position: absolute;
    right: 0;
    z-index: 1;
}

.RowClassName {
    padding: 8px;
}

.Checkbox {
    margin-right: 8px;
}
