@import "../../../../assets/style-params.module";

.InsightStudioHeader {
    margin-bottom: 16px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    position: relative;
    height: 24px;
    min-height: 48px;
    box-sizing: border-box;

    :global(.left-wrapper) {
        display: flex;
        align-items: center;
    }

    :global(.right-wrapper) {
        display: flex;
        align-items: center;

        > div {
            margin-right: 8px;
        }

        > div:last-child {
            margin-right: 0px;
        }
    }

    :global(.icon-wrapper) {
        background: $color-daintree;
        margin-right: 8px;

        img {
            height: 22px;
            width: 22px;
            display: block;
        }
    }

    :global(.text-wrapper) {
        display: inline-flex;
        align-items: center;

        span {
            display: inline-flex;
            align-items: center;
            vertical-align: middle;
            font-size: 13px;
            line-height: 16px;
            margin: 0;

            & + span {
                margin-left: 18px;
                padding-left: 16px;
                border-left: 1px solid #CFCECE;
            }
        }

        a {
            color: $color-curious-blue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    :global(.button) {
        background: transparent;
        color: $color-curious-blue;
        font-family: Arial, sans-serif;
        font-size: 13px;
        line-height: 16px;
        text-decoration: none;
        padding: 0;
        border: 0;
        outline: 0;
        display: inline;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    :global(.buttons-wrapper) {
        & > button {
            margin-left: 8px;
        }

        :global(.button-read-only) {
            color: $color-dove-grey;
            display: inline-flex;
            justify-content: space-around;
            align-items: center;
            cursor: default;

            &:hover {
                text-decoration: none;
            }
        }

        :global(.button-read-only__lock-icon) {
            height: 10px;
            width: 7px;
            background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%2214%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.75%204.375h-.625v-1.25a3.126%203.126%200%2000-6.25%200v1.25H1.25c-.688%200-1.25.563-1.25%201.25v6.25c0%20.688.563%201.25%201.25%201.25h7.5c.688%200%201.25-.563%201.25-1.25v-6.25c0-.688-.563-1.25-1.25-1.25zM5%2010c-.688%200-1.25-.563-1.25-1.25%200-.688.563-1.25%201.25-1.25.688%200%201.25.563%201.25%201.25C6.25%209.438%205.687%2010%205%2010zm1.938-5.625H3.063v-1.25A1.94%201.94%200%20015%201.187a1.94%201.94%200%20011.938%201.938v1.25z%22%20fill%3D%22%23404040%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
            background-repeat: no-repeat;
            background-size: contain;
        }

        :global(.button-read-only__title) {
            margin-right: 8px;
        }
    }
}

.pdfDropdown {
    display: inline-flex;
    flex-direction: column;
    width: 150px;

    & > * {
        cursor: pointer;
        padding: 8px;
        color: $color-black;

        &:hover {
            background-color: rgba(41, 171, 226, 0.05);
        }
    }
}
