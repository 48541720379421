@import "../../../../assets/style-params.module";
@import "../mixins.module";

.ViewModal {
    height: 90vh;
    display: flex;
    flex-direction: column;

    :global(.content) {
        @include formViewMixin();
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        flex: 1;

        p {
            margin: 0;
        }

        :global(.bottom) {
            margin-top: 24px;

            :global(.footer) {
                display: flex;
                justify-content: flex-end;

                :global(.cancel-btn) {
                    margin-right: 8px;
                }
            }
        }

        :global(.properties) {
            margin: 16px 0;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: auto;

            :global(.property) {
                display: flex;
                align-items: center;

                > p {
                    margin: 4px 0;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }

                :global(.dot) {
                    min-width: 24px;
                }
            }
        }
    }
}
