@import "../../../../assets/style-params.module";

.HomepageMenuOptionDropdownContent {
    box-sizing: content-box;
    padding: 0 16px 15px;
    background: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    min-width: 206px;

    :global(.option):global(.isHeader) {
        margin-top: 8px;
    }

    :global(.option) {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        padding: 8px;
        color: #303030;
        line-height: 16px;

        &:hover {
            background-color: rgba(41, 171, 226, 0.05);
        }

        span {
            margin-right: 4px;
        }

        :global(.indicators) {
            display: flex;
            align-items: center;
            margin-left: auto;

            & > *:not(:first-of-type) {
                margin-left: 8px;
            }
        }
    }

    :global(.option):global(.isHeader) {
        font-weight: 600;
        cursor: initial;

        &:hover {
            background-color: transparent;
        }
    }

    &:global(.hidden) {
        display: none;
    }

    :global(.tooltip) {
        display: flex;
        align-items: center;
        box-sizing: border-box;

        :global(.option) {
            width: 100%;
        }
    }
}
