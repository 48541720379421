
body {
    background-color: #ededed;
    font-family: Arial, sans-serif;
    font-size: 13px;
    overflow: hidden;

    // Insight Studio Settings modal
    &:global(.modal-open) {
        display: flex;
        flex-direction: column;
        position: relative;

        :global(.is-widget-layout) {
            overflow: hidden !important;
        }

        :global(.modal-container):global(.modal-container--visible):not(:global(.settings-modal)) {
            overflow: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        #portalRoot {
            position: absolute;
            overflow-y: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

:global(.gray-text) {
    color: #707070 !important;
}

// =============  Hmome page  ========================
:global(.category-score-header) {
    height: 50px;
    border-bottom: solid 2px #f8f8f8;
    width: 100%;
}

// =============  Components  ========================
:global(.cell-badge) {
    cursor: help;
    margin-left: 10px;
}

@page {
    margin: 0.5cm;
    size: A4;
    background: white;
}

* {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    print-color-adjust: exact !important; /* Firefox */
}

:global(.printWindow) {
    position: relative;
    background: white;
}

@media print {
    :global(.main-content), body {
        background: white;
    }

    #app, #userflow-ui, #is--help-banner, :global(.modal-overlay), :global(.modal-inner-container), :global(.helpcrunch-iframe-wrapper) {
        display: none;
    }
}
