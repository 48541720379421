@import "../../../../../../assets/style-params.module";
@import "../../../../../../assets/mixins.module";

.propertyElement {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.propertyElementDropdownBodyWrapper {
    width: fit-content;
}

.dropdownWrapper {
    z-index: 20002;
}

.propertyElementAddToScrollContainer {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    height: 100%;
    @include hideScrollbar();

    & > span {
        padding: 5px 0;
        @include listElementHover();

        &:global(.disabled) {
            cursor: not-allowed !important;
            background-color: #f6f6f6 !important;
            color: #707070 !important;
            opacity: 0.2;
        }
    }
}

.propertyElementDropdownButton {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    width: 108px;
    border: 0;
    padding: 0 4px 0 8px;
    @include font-label-copy-default();
}
