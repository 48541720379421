@import "../../../assets/style-params.module";

.HeaderStar {
    display: flex;
    align-items: inherit;
    margin-left: -10px !important;

    :global(.star) {
        width: 24px;
        height: 24px;
        margin-right: 5px;

        :global(.content) {
            input {
                -moz-appearance: initial;
                visibility: hidden;
                cursor: pointer;
                transform: translate(0px, -3px);
                width: 24px;
                height: 24px;
            }

            input:before {
                position: absolute;
                content: "";
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 4c.282 0 1.195 1.41 2.739 4.23 3.161.573 4.784 1.006 4.87 1.298.085.292-.974 1.596-3.178 3.912.367 3.22.457 4.897.271 5.032-.186.135-1.753-.47-4.702-1.813-2.954 1.349-4.522 1.953-4.702 1.813-.18-.14-.09-1.817.27-5.032-2.207-2.273-3.266-3.577-3.176-3.912.089-.335 1.712-.768 4.87-1.298C10.804 5.41 11.717 4 12 4zm0 2.203c-.185 0-.785 1.072-1.799 3.216-2.45.366-3.704.664-3.762.895-.06.232.825 1.131 2.65 2.7-.468 2.487-.643 3.779-.525 3.875.119.097 1.264-.455 3.436-1.653 2.175 1.195 3.324 1.746 3.446 1.653.123-.093-.056-1.385-.536-3.875.15-.128.292-.251.429-.37l.391-.344c1.307-1.154 1.937-1.816 1.89-1.986-.056-.201-1.33-.5-3.821-.895-1.014-2.144-1.614-3.216-1.8-3.216z' fill='%23404040'/%3E%3C/g%3E%3C/svg%3E");
                visibility: visible;
            }

            input:checked:before {
                content: "";
                width: 24px;
                height: 24px;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 16.66c-2.954 1.348-4.522 1.952-4.702 1.812-.18-.14-.09-1.817.27-5.032-2.207-2.273-3.266-3.577-3.176-3.912.089-.335 1.712-.768 4.87-1.298C10.804 5.41 11.717 4 12 4c.282 0 1.195 1.41 2.739 4.23 3.161.573 4.784 1.006 4.87 1.298.085.292-.974 1.596-3.178 3.912.367 3.22.457 4.897.271 5.032-.186.135-1.753-.47-4.702-1.813z' fill='%23F58231'/%3E%3C/g%3E%3C/svg%3E");
                visibility: visible;
            }
        }
    }
}
