@mixin formViewMixin() {
    :global(.controllers) {
        display: flex;
        flex-direction: column;
    }

    :global(.add) {
        display: flex;

        :global(.add-picker) {
            flex: 1;
        }

        :global(.add-list-picker) {
            > button {
                width: 100%;
            }
        }

        :global(.label) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 24px;

            > label {
                @include font-label-copy-bold();
            }
        }
    }
}
