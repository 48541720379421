@import "../../../../../assets/style-params.module";

.NewDataNotification {
    ul {
        width: 95%;
        padding: 0;
        padding-right: 8px;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow: auto;
        flex: 1;

        li {
            width: 100%;
            min-height: 16px;
            display: flex;
            align-items: center;

            > div {
                display: flex;
                justify-content: center;
            }

            > div:first-child {
                flex: 1;
                margin-top: 8px;
                justify-content: left;
                cursor: pointer;
                color: $color-summer-sky;
                overflow: hidden;

                > div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .link {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        color: $color-summer-sky;
    }
}