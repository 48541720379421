@import "../../../assets/style-params.module";

.ManageFavoritesTab {
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    :global(.top) {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        :global(.header) {
            :global(.header-text) {
                font-size: 20px;
                line-height: 24px;
            }

            :global(.controllers) {
                display: flex;
                justify-content: space-between;
            }
        }

        :global(.favorites) {
            overflow: hidden;
            display: flex;
            flex-direction: column;

            :global(.select-all-remove) {
                margin: 0;
                margin-bottom: 16px;
            }

            :global(.spacer) {
                display: none;
            }
        }
    }

    :global(.bottom) {
        :global(.footer) {
            > p {
                color: black;
            }
        }
    }
}
