@import "../../../assets/style-params.module";

.InsightStudioTemplateSelection {
    max-width: 217px;
    height: 32px;
    border: 1px solid #C9C8C8;
    border-radius: 0;
    padding: 0 8px;

    :global(.text) {
        display: none;
    }

    :global(.template) {
        color: #000;
        font-size: 13px;
    }
}

.divider {
    height: 100%;
    width: 100%;
    background: #f4f4f4;
}

.ButtonDelete {
    @include button-error-outline-type();
}

.primaryButtonClassName {
    @include button-primary-type();
    padding: 8px 16px;
    border: none;
    height: 32px;
}
