@import "../../assets/style-params.module";
@import "../../assets//mixins.module.scss";

.agGrid {
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;

    :global(.ag-root-wrapper-body) {
        flex: none !important;
        height: unset;

        &:global(.ag-layout-normal) {
            height: auto;
        }
    }

    :global(.ag-pinned-left-cols-container), :global(.ag-pinned-left-header) {
        box-shadow: 0px 3px 6px 2px rgba(74, 74, 74, 0.2);
        z-index: 1;

        :global(.ag-cell-last-left-pinned) {
            border-right-color: #F8F8F8 !important;
        }
    }

    :global(.ag-pinned-left-header) {
        overflow: visible;
    }

    :global(.ag-header) {
        background-color: white;
    }

    :global(.ag-header-viewport) {
        &:after {
            top: 32px;
        }
    }

    :global(.ag-header-cell), :global(.ag-cell) {
        border: 1px solid #F8F8F8;
        line-height: 16px;
    }

    :global(.ag-header-row) {
        overflow: visible;
        background-color: white;
    }

    :global(.ag-header-cell) {
        font-family: Arial, sans-serif;
        font-weight: 700;
        box-sizing: border-box;
        height: 100%;
        display: block;
        overflow: visible;

        :global(.ag-icon) {
            display: none;
        }

        :global(.ag-header-cell-comp-wrapper) {
            height: 100%;
            display: block;

            div {
                cursor: pointer;
            }
        }

        &:global(.ag-header-cell-sortable) {
            background-repeat: no-repeat;
            background-position: calc(100% - 4px) 50%;
        }

        &:global(.ag-header-cell-sortable) {
            @include sortable-header-icons;
        }
    }

    :global(.ag-body-viewport) {
        height: auto;
    }

    :global(.ag-row) {
        :global(.ag-cell) {
            box-sizing: border-box;
            outline: 0px none;
            padding: 15px;

            &:global(.padding-disabled) {
                padding: unset;
            }

            &:global(.highlight) {
                background: #e3f2fb;
            }
        }

        :global(.ag-cell-wrapper) {
            width: 100%;
            height: 100%;

            :global(.ag-react-container) {
                height: 100%;
            }
        }

        &:global(.ag-row-even) {
            background: white;
        }

        &:global(.ag-row-odd) {
            background: #F6F6F6;
        }

        &:global(.ag-row-selected) {
            background: #29abe2;

            :global(.ag-cell) {
                border-color: #F6F6F6;
            }
        }
    }

    :global(.ag-center-cols-viewport) {
        :global(.ag-cell) {
            text-align: right;

            :global(.ag-cell-wrapper) {
                width: 100%;
                display: block;
            }
        }
    }

    :global(.ag-cell-normal-height):global(.checkbox) {
        padding: 0;

        :global(.ag-cell-wrapper) {
            display: flex;
        }
    }

    :global(.ag-cell-wrapper) {
        display: block;
    }

    :global(.ag-selection-checkbox) {
        width: 100%;
    }

    :global(.ag-selection-checkbox), :global(.ag-checkbox), :global(.ag-checkbox-input-wrapper) {
        flex: 1 1 auto;
    }

    :global(.ag-checkbox-input-wrapper) {
        display: flex;
        width: 100%;

        &::after {
            content: unset;
        }

        :global(.ag-checkbox-input) {
            opacity: 1;
            -webkit-appearance: auto;
        }
    }

    :global(.ag-body-vertical-scroll) {
        box-shadow: 0px 0px 6px 1px rgba(74, 74, 74, 0.2);
        clip-path: inset(0px -10px -10px -10px);
    }
}

.actionButton {
    margin-left: 16px;
}

.agGridEnableCellTextSelection {
    :global(.ag-pinned-left-cols-container) :global(.ag-cell) {
        user-select: text !important;
    }
}
