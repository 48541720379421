.placeholder {
    animation: placeHolderShimmer 1200ms linear infinite normal;
    background-color: #f6f6f6;
    background-image: linear-gradient(90deg, rgba(246, 246, 246, 1) 0%, rgba(238, 238, 238, 1) 50%, rgba(246, 246, 246, 1) 100%);
    background-size: 500px auto;
    overflow: hidden;
    vertical-align: top;

    @keyframes placeHolderShimmer {
        0% {
            background-position: -500px 0;
        }

        100% {
            background-position: 500px 0;
        }
    }
}
