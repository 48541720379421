@import "../../assets/animations.module";
@import "../../assets/style-params.module";

.widget {
    :global(.footer), :global(.header) {
        &:global(.draggable-handle) {
            cursor: grab;
        }
    }
}

.WidgetHeaderSettings {
    display: flex;

    :global(.sh__is-screenshotting) & {
        display: none;
    }
}

.nonFocusable {
    :global(.options) :global(.option) {
        &:global(.focused) {
            background-color: white;

            &:hover {
                background-color: rgba(41, 171, 226, 0.05);
            }
        }
    }
}

.widthSizer {
    :global(.options) {
        :global(.option) {
            padding: 8px 0;
        }

        :global(.option__width-100) {
            width: 100%;
        }
    }
}

.simplePickerDropdown {
    width: 222px;
}

:global(.react-grid-layout):global(.sh__is-screenshotting) {
    .widget {
        :global(.header), :global(.footer), :global(.content) {
            background-color: transparent;
        }

        :global(.header) {
            :global(.buttons) {
                display: none;
            }
        }
    }
}

.Loader {
    animation: rotation 1s linear infinite;
}
