.control {
    width: 100%;

    button:global(.dropdown) {
        overflow: hidden;
        padding-left: 8px;

        span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
