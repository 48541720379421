.propertyCell {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    align-items: center;

    img {
        display: block;
        height: auto;
        width: 46px;
        min-width: 46px;
    }

    :global(.cell-value) {
        padding: 0 16px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
