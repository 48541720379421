@import "../../../../assets/animations.module";
@import "../../../../assets/style-params.module";

.PropertiesList {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 16px 0;
    overflow: hidden;

    :global(.remove) {
        margin-bottom: 16px;
    }

    :global(.list) {
        overflow-y: auto;
        display: flex;
        flex: 1;
        flex-direction: column;

        :global(.loader) {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            :global(.loader-img) {
                animation: rotation-left 1s infinite linear;
                overflow: hidden;
                display: flex;
                flex-direction: column;
            }
        }

        :global(.load-more) {
            display: flex;
            justify-content: center;
            margin-top: 2px;
        }

        :global(.property-row) {
            margin: 4px 0;
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-height: 24px;

            :global(.separated) {
                margin: 4px 8px;
            }
        }
    }
}
