.initials {
    width: 24px;
    line-height: 24px;
    border-radius: 50%;
    background-color: #29abe2;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
    font-size: 13px;
}
