@import "../../../../assets/style-params.module";

.ImportDashboardsContainer {
    :global(.message) {
        font-size: 13px;
        padding-bottom: 12px;
    }

    :global(.reports-list) {
        max-height: 400px;
        overflow-y: auto;
    }

    :global(.footer) {
        border-top: none;
    }

    :global(.close) {
        width: 80px;
    }

    :global(.import) {
        width: 110px;
        margin-left: 8px;

        &:global(.loading) {
            height: 32px;
        }
    }
}

.NotYetImportedOptions {
    :global(.option) {
        padding-bottom: 12px;

        :global(.separated) {
            padding-right: 8px;
        }

        :global(.addition) {
            flex: 1 1 auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 8px;
            color: $color-dove-grey;
        }
    }
}

.AlreadyImportedOptions {
    padding-top: 8px;

    :global(.label) {
        font-weight: bold;
        padding-bottom: 16px;
    }

    :global(.option) {
        cursor: unset;
        padding-bottom: 8px;

        :global(.separated) {
            display: none;
        }

        :global(.addition) {
            flex: 1 1 auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 8px;
            color: $color-dove-grey;
        }
    }
}
