@import "../../../../assets/style-params.module";

.ContentTagRenderer {
    display: flex;

    :global(.tag) {
        padding: 4px 8px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 26px !important;

        &:global(.first) {
            overflow: hidden;

            span {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        &:global(.more) {
            background: $color-tundora;
            color: white;
        }

        &:not(:last-child) {
            margin-right: 8px;
            margin-bottom: 8px;
        }

        img {
            padding-right: 4px;
            filter: brightness(3.8);

            &:global(.private) {
                width: 13px;
            }

            &:global(.company) {
                width: 18px;
            }

            &:global(.public) {
                width: 22px;
            }
        }

        span {
            font-family: Arial, sans-serif;
            font-size: 13px;
            font-weight: normal;
        }
    }

    :global(.rem) {
        width: 200px;
        background: red;
    }
}

.remTag {
    display: flex;
    align-items: center;
}
