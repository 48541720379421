@import "../../assets/style-params.module";

.notificationPopup {
    background-color: $color-curious-blue;
    display: flex;
    align-items: center;
    padding: 8px;
    @include font-label-copy-bold();
    color: #fff;
    font-weight: 700;
    border-radius: 4px;

    :global(.content) {
        width: 272px;

        &:global(.pointing) {
            cursor: pointer;
        }
    }

    :global(.close-button) {
        display: flex;
        align-items: center;
        margin-left: 25px;
        cursor: pointer;

        img {
            width: 32px;
            height: 100%;
        }
    }

    &:global(.red) {
        background-color: $color-well-read;
    }

    &:global(.classic-export-pill) {
        background-color: #fae8b6;

        :global(.close-button) {
            filter: invert(100%);
        }
    }
}
