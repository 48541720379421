.RadioButtonListRow {
    position: relative;
    cursor: pointer;

    :global(.radio) {
        position: absolute;
    }

    :global(.label) {
        margin-left: 26px;
        line-height: 18px;
    }

    :global(.hint) {
        padding-left: 26px;
        color: #929292;
    }
}

.Disabled {
    filter: opacity(0.3);
    cursor: not-allowed !important;
}
