@import "../../assets/style-params.module";

.NewTreeSelect {
    padding: 16px;

    :global(.branch) {
        :global(.branch-header) {
            position: relative;
            display: flex;
            align-items: center;
            padding: 4px 0;

            :global(.chevron-wrapper) {
                width: 24px;
                height: 24px;
                margin-right: 8px;
                flex-shrink: 0;

                :global(.chevron-img) {
                    cursor: pointer;
                }
            }

            :global(.ladder) {
                width: 14px;
                height: 18px;
                border-left: 2px dotted $color-silver;
                border-bottom: 2px dotted $color-silver;
                position: absolute;
                top: -4px;
                left: -16px;
            }
        }

        :global(.branch-header)>div {
            width: 100%;
        }

        :global(.branch-items) {
            margin-left: 28px;
        }

        :global(.disabled-checkbox) {
            margin-left: 0;
        }

        :global(.skip-chevron) {
            margin-left: -4px;
        }

        :global(.disabled-checkbox-parent) {
            div:hover {
                cursor: inherit;
            }

            div > div {
                display: none;
            }

            :global(.radio) {
                display: none;
            }

            span {
                margin-left: 0px;
            }
        }

        &:global(.leaf) {
            margin-left: 32px;

            :global(.ladder) {
                top: 0;
                height: 12px;
                left: -20px;
            }
        }

        &:global(.closed) {
            :global(.chevron-img) {
                transform: rotateZ(-90deg);
            }

            :global(.branch-items) {
                display: none;
            }
        }
    }

    :global(.option) {
        >div {
            margin-right: 8px;
        }
    }
}

.CheckboxListRow {
    span {
        flex-basis: 60%;
    }

    :global(.addition) {
        color: $color-dove-grey;
        flex: 1;
        text-align: right;
        padding-right: 0 !important;
    }

    :global(.hide-checkbox) {
        display: none !important;
    }
}
