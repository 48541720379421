@import "../../../assets/style-params.module";

.list {
    width: 100%;
    background-color: #fff;
    max-height: 464px;
    display: flex;
    flex-direction: column;
    padding-bottom: 11px;

    :global(.header) {
        font-weight: bold;
        display: grid;
        grid-template-columns: 24px auto;

        span {
            margin: auto 0 auto 5px;
        }

        img {
            cursor: pointer;
        }
    }

    :global(.grid-header) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        :global(.label) {
            margin-right: 30px;
            font-size: 12px;
            color: $color-dove-grey;
        }
    }

    :global(.options-list) {
        flex: 1 1 auto;
        overflow-y: auto;
    }

    :global(.options-header) {
        margin-left: 8px;
        font-weight: bold;
    }

    :global(.option) {
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        line-height: 16px;
        padding: 8px;
        height: 32px;

        :global(.separated) {
            margin-right: 8px;
        }

        :global(.addition) {
            flex: 1 1 auto;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }

    :global(.notice), :global(.footer) {
        :global(.link) {
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    :global(.notice) {
        margin-top: 32px;
        background-color: #fef4e7;

        :global(.link) {
            display: inline-block;
        }
    }

    :global(.footer) {
        margin-top: 16px;
        padding-top: 16px;
        border: 1px solid $color-wild-sand;
    }
}

.emptyMsgWrapper {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    :global(.empty-msg) {
        top: auto;

        :global(.message) {
            width: 248px;
            margin: 0 auto;
        }
    }

    button {
        margin: 16px auto;
    }
}
