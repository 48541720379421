@import "../../../assets/style-params.module";

.TooltipContent {
    width: 135px;
    text-align: center;
}

.Button {
    background-color: #2bace1;
    color: #fff;
    border: 0;
    border-radius: 40px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    height: 100%;
    width: 96px;

    &:global(.disabled) {
        background: transparent;
        color: #fff;
    }

    &:hover {
        &:not(:global(.disabled)) {
            color: $color-gummy-dolphins;
            background: $color-white-solid;
        }
    }
}
