@import "../../../../assets/mixins.module";
@import "../../../../assets/style-params.module";

.container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    flex: 1;

    :global(.media-select-buttons) {
        margin: 16px 0;

        > div {
            padding: 4px 0;
        }

        :global(.error-message) {
            text-align: center;
        }
    }

    :global(.properties-list) {
        color: $color-black-solid;
        padding-top: 12px;
        padding-bottom: 44px;
        height: 252px;
        overflow-y: scroll;
        @include hideScrollbar();

        div {
            display: flex;

            :global(.category-pills) {
                > div {
                    margin-left: 4px;
                }
            }

            :global(.property-element-style) {
                width: 100%;
                color: $color-black-solid;
            }

            &:global(.placeholder) {
                height: 32px;
            }
        }
    }

    :global(.notice) {
        font-size: 13px;
        background: $color-old-lace;

        &:global(.gray) {
            color: $color-silver;
        }
    }

    :global(.bottom) {
        :global(.link) {
            cursor: pointer;
            font-weight: bold;
            color: black !important;
            padding-left: 8px;
        }
    }
}

.loader {
    height: 400px;
}

.tooltipContent {
    text-align: center;
}

.Pickers {
    width: 100%;
    display: flex;
    margin-bottom: 16px;

    :global(.border-right-not-visible) {
        > div:first-child {
            input {
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    :global(.search-container-wrapper) {
        height: 32px;
        margin: 0 !important;
    }
}
