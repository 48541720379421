@import "../../../../assets/style-params.module";

.bulkEditDateDropdownElement {
    display: flex;
    align-items: center;
    padding: 8px;
    box-sizing: border-box;
    user-select: none;
    cursor: pointer;

    &:hover {
        background-color: rgba(41, 171, 226, 0.05);
    }

    span {
        margin-left: 8px;
        @include font-label-copy-default();
    }

    &:global(.disabled) {
        &:hover {
            background-color: initial;
            cursor: not-allowed;
        }

        span {
            color: $color-dove-grey;
        }

        :global(.lock-icon) {
            margin-left: auto;
        }
    }
}
