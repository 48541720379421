@mixin custom-media-query($width) {
    @media screen and (max-width: #{$width}) {
        @content;
    }
}

@mixin forSm() {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin hideScrollbar() {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin listElementHover() {
    cursor: pointer;

    &:hover {
        background-color: rgba(41, 171, 226, 0.05);
    }
}

@mixin dropdownShadow() {
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
}

@mixin postSnippetStyle() {
    height: 50px;
    overflow: hidden;
    text-decoration: underline;
    cursor: pointer;
    user-select: none;

    :global(.post-title) {
        @include font-label-copy-semi-bold();
    }

    :global(.post-content) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

@mixin groupToggle() {
    display: flex;
    align-items: center;
    justify-content: right;
    padding-bottom: 16px;

    label {
        padding-right: 12px;
    }

    &:global(.disabled) {
        * {
            cursor: not-allowed;
        }
    }

    :global(.toggle) {
        width: 15px;

        input {
            &:checked + :global(.slider) {
                background-color: $color-17;
            }

            &:checked + :global(.slider):before {
                transform: translateX(11px);
            }
        }
    }
}

@mixin onListElementHover() {
    &:hover {
        background: rgba(41, 171, 226, 0.05);
    }
}

@mixin sortable-header-icons {
    &[aria-sort="none"] {
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20fill%3D%22%23002032%22%20d%3D%22M8%2C11.9250421%20L4.97548959%2C8.83752104%20C4.75233285%2C8.60971521%204.39052429%2C8.60971521%204.16736755%2C8.83752104%20C3.94421082%2C9.06532688%203.94421082%2C9.43467312%204.16736755%2C9.66247896%20L7.59593898%2C13.162479%20C7.81909572%2C13.3902848%208.18090428%2C13.3902848%208.40406102%2C13.162479%20L11.8326324%2C9.66247896%20C12.0557892%2C9.43467312%2012.0557892%2C9.06532688%2011.8326324%2C8.83752104%20C11.6094757%2C8.60971521%2011.2476671%2C8.60971521%2011.0245104%2C8.83752104%20L8%2C11.9250421%20Z%22/%3E%3Cpath%20fill%3D%22%23002032%22%20d%3D%22M8%2C5.92504209%20L4.97548959%2C2.83752104%20C4.75233285%2C2.60971521%204.39052429%2C2.60971521%204.16736755%2C2.83752104%20C3.94421082%2C3.06532688%203.94421082%2C3.43467312%204.16736755%2C3.66247896%20L7.59593898%2C7.16247896%20C7.81909572%2C7.39028479%208.18090428%2C7.39028479%208.40406102%2C7.16247896%20L11.8326324%2C3.66247896%20C12.0557892%2C3.43467312%2012.0557892%2C3.06532688%2011.8326324%2C2.83752104%20C11.6094757%2C2.60971521%2011.2476671%2C2.60971521%2011.0245104%2C2.83752104%20L8%2C5.92504209%20Z%22%20transform%3D%22matrix%281%200%200%20-1%200%2010%29%22/%3E%3C/g%3E%3C/svg%3E");
    }

    &[aria-sort="descending"] {
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23002032%22%20fill-rule%3D%22evenodd%22%20d%3D%22M4%2C3.25837542%20L0.975489589%2C0.170854378%20C0.752332851%2C-0.0569514592%200.390524292%2C-0.0569514592%200.167367554%2C0.170854378%20C-0.0557891845%2C0.398660214%20-0.0557891845%2C0.768006452%200.167367554%2C0.995812289%20L3.59593898%2C4.49581229%20C3.81909572%2C4.72361813%204.18090428%2C4.72361813%204.40406102%2C4.49581229%20L7.83263245%2C0.995812289%20C8.05578918%2C0.768006452%208.05578918%2C0.398660214%207.83263245%2C0.170854378%20C7.60947571%2C-0.0569514592%207.24766715%2C-0.0569514592%207.02451041%2C0.170854378%20L4%2C3.25837542%20Z%22%20transform%3D%22translate%284%206%29%22/%3E%3C/svg%3E");
    }

    &[aria-sort="ascending"] {
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20fill%3D%22%23002032%22%20fill-rule%3D%22evenodd%22%20d%3D%22M4%2C3.59170876%20L0.975489589%2C0.504187711%20C0.752332851%2C0.276381874%200.390524292%2C0.276381874%200.167367554%2C0.504187711%20C-0.0557891845%2C0.731993548%20-0.0557891845%2C1.10133979%200.167367554%2C1.32914562%20L3.59593898%2C4.82914562%20C3.81909572%2C5.05695146%204.18090428%2C5.05695146%204.40406102%2C4.82914562%20L7.83263245%2C1.32914562%20C8.05578918%2C1.10133979%208.05578918%2C0.731993548%207.83263245%2C0.504187711%20C7.60947571%2C0.276381874%207.24766715%2C0.276381874%207.02451041%2C0.504187711%20L4%2C3.59170876%20Z%22%20transform%3D%22matrix%281%200%200%20-1%204%2010.333%29%22/%3E%3C/svg%3E");
    }
}
