@import "../../../assets/animations.module";
@import "../../../assets/style-params.module";
@import "../../../assets/mixins.module";

.ManageBrandsAndCategoriesCard {
    position: relative;
    max-width: min-content;
    min-width: 468px;
    min-height: 500px;
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 2002;
    padding: 0;
    box-shadow: 0 2px 6px 3px rgba(74, 74, 74, 0.2);
    box-sizing: border-box;

    :global(.tabs) span {
        min-width: max-content;
    }

    :global(.vertical-line) {
        border-top: rgb(246, 246, 246) 1px solid;
        bottom: 63px;
        position: absolute;
        width: calc(100% - 32px);
    }

    :global(.picker) {
        display: flex;
        flex-direction: column;
        padding: 15px;
        flex: 1;
        overflow-y: auto;

        :global(.control-row) {
            padding-bottom: 16px;
        }

        :global(.request-note) {
            padding: 16px;
            background-color: $color-caribbean-green;

            &:global(.error) {
                background-color: $color-5;
            }
        }

        :global(.clear-button) {
            color: $color-summer-sky;
            font-size: 13px;
            cursor: pointer;
            text-align: right;
            width: 100%;
            border-bottom: solid 1px #e0dade;
            padding-bottom: 16px;
        }

        :global(.properties-list) {
            color: $color-black-solid;
            padding-top: 12px;
            padding-bottom: 44px;
            height: 252px;
            overflow-y: auto;

            div {
                display: flex;

                :global(.category-pills) {
                    > div {
                        margin-left: 4px;
                    }
                }

                :global(.property-element-style) {
                    width: 100%;
                    color: $color-black-solid;
                }

                &:global(.placeholder) {
                    height: 32px;
                }

                :global(.right-aligned-text) {
                    color: $color-dove-grey;
                    margin-left: auto;
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 2px;
                    }
                }
            }
        }

        :global(.loading-text) {
            padding: 8px;
        }

        :global(.pills) {
            height: auto;
            max-height: 84px;
            padding-top: 16px;

            :global(.selection) {
                background: $color-bunker;
                border: 0;
                border-radius: 12px;
                margin: 0 4px 4px 0;
                overflow: hidden;
                padding: 0 32px 0 8px;
                position: relative;

                :global(.name) {
                    color: $color-white-solid;
                    font-size: 12px;
                    line-height: 24px;
                }

                :global(.type) {
                    display: none;
                }

                img:global(.close) {
                    background: $color-tundora;
                    border-radius: 12px;
                    position: absolute;
                    right: 0;
                }
            }
        }

        :global(.pills):global(.without-closing) {
            :global(.selection) {
                padding: 0 8px;
            }
        }

        :global(.search-container-wrapper) {
            width: 100%;
            margin: 0 0 16px 0;
        }

        :global(.property-line) {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        :global(.error-line) {
            padding-top: 16px;
        }

        :global(.row) {
            display: flex;
            flex-direction: row;

            >div, >button {
                min-width: max-content;
            }

            >button {
                align-items: baseline;

                :global(.chevron) {
                    height: 32px;
                }
            }
        }

        :global(.horizontal-divider) {
            height: 1px;
            width: 432px;
            background-color: $color-iron;
            margin: 15px 0;
        }

        :global(.no-favorites) {
            display: flex;
            flex-direction: column;
            font-size: 13px;
            padding-top: 8px;
            padding-bottom: 8px;

            :global(.headline) {
                font-weight: bold;
            }

            :global(.text) {
                padding-top: 16px;
            }
        }

        :global(.options) {
            height: 100%;
            overflow-y: auto;
        }

        &:global(.industries) {
            overflow-y: auto;
        }

        :global(.shared-picker) {
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        :global(.option) {
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            padding: 8px;
            color: $color-black;
            font-size: 13px;
            line-height: 18px;
            height: 32px;

            :global(.separated) {
                margin-right: 8px;
            }

            &:hover, &:global(.focused) {
                background-color: rgba(41, 171, 226, 0.05);
            }

            :global(.addition) {
                flex: 1 1 auto;
                display: flex;
                justify-content: flex-end;
            }

            :global(.pill) {
                margin: -4px 4px 0px 0px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    :global(.notice) {
        font-size: 13px;
        background: $color-old-lace;

        &:global(.gray) {
            color: $color-silver;
        }
    }

    :global(.bottom) {
        :global(.link) {
            cursor: pointer;
            font-weight: bold;
            color: black !important;
            padding-left: 8px;
        }
    }

    :global(.loading) {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        align-items: center;

        img {
            animation: rotation-left 1s infinite linear;
        }
    }

    :global(.entities-pills-wrapper) {
        padding: 0 16px;
        margin: auto 0 16px 0;

        :global(.divider) {
            height: 1px;
            width: 100%;
            border-top: 1px solid $color-wild-sand;
            margin-bottom: 16px;
        }

        :global(.entities-list) {
            display: flex;
            align-items: center;
            margin-top: auto;

            :global(.clear-entities) {
                font-size: 13px;
                color: $color-summer-sky;
                cursor: pointer;
                margin-left: 16px;
            }

            :global(.entities-pills) {
                height: fit-content;
                max-height: 64px;
                overflow-y: auto;
                width: 100%;

                &:global(.without-closing) {
                    :global(.selection) {
                        padding: 0 9px;
                    }
                }

                :global(.selection) {
                    border-radius: 4px;
                    padding: 0 0 0 9px;
                    background-color: #BCE6F8;
                    border: 0;

                    & > div {
                        :global(.type) {
                            display: none;
                        }

                        :global(.name) {
                            @include font-supplimentary-copy();
                            line-height: 24px;
                            color: $color-bunker;
                        }
                    }
                }
            }
        }
    }

    :global(.footer) {
        display: flex;
        justify-content: flex-end;
        padding: 0 16px 16px 16px;

        button {
            width: 88px;

            &:first-of-type {
                margin-right: 8px;
            }
        }
    }
}
