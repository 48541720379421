@import "../../assets/style-params.module";

.button {
    background: #fff;
    border: 1px solid $color-curious-blue;
    border-radius: 3px;
    box-sizing: border-box;
    color: $color-curious-blue;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: normal;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 30px;
    outline: 0 none;
    padding: 0 16px;
    vertical-align: top;

    & > a {
        font-size: inherit;
        color: inherit;
        text-decoration: none;
        height: 100%;
        width: 100%;
        margin: 0 -10px;
        padding: 0 10px;
    }

    &:hover {
        &:not(:global(.hover-disabled)) {
            color: #fff;
            background: $color-curious-blue;
        }
    }

    &:active {
        background: $color-curious-blue;
        color: #fff;
    }

    &:global(.primary) {
        background: $color-curious-blue;
        color: #fff;

        & > a {
            font-size: inherit;
            color: inherit;
        }

        &:hover {
            &:not(:global(.hover-disabled)) {
                background: $color-curious-blue-darker;
                border-color: $color-curious-blue-darker;
            }
        }

        &:active {
            background: #fff;
            color: $color-curious-blue;
        }
    }

    &:global(.disabled) {
        border: 1px solid $color-wild-sand;
        background-color: $color-wild-sand;
        color: $color-dove-grey;
        opacity: 0.2;
        cursor: not-allowed;
    }

    &:global(.loading) {
        background-color: $color-silver-2;
        border: 0;

        & > img {
            animation: rotation-left 1s infinite linear;
        }
    }
}
