.rowOwnerRenderer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
        cursor: pointer;
    }
}

.dropdownButton {
    border: none;
    background: none;
}
