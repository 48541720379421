.errorBoundaryContainer {
    align-items: center;
    background: #ededed;
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.notIdealState {
    margin: 0px;
}
