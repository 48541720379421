.dropdown {
    height: 24px;
}

.settingsPickerDropdown {
    width: 248px;
}

.button {
    stroke: #404040;
    cursor: pointer;

    &:hover {
        stroke: #29abe2;
    }
}

.settingsDropdownButton {
    border: none;
    background: none;
    padding: 0;
}

.settingsDropdownContent {
    :global(.option) {
        padding: 8px;
        line-height: 16px;
        cursor: pointer;

        &:hover {
            background: rgba(41, 171, 226, 0.05);
        }
    }
}

.addButton {
    min-width: 101.2px;
    min-height: 32px;
}
