.dropdownBody {
    position: relative;
    width: 480px;
    z-index: 2002;
    box-shadow: 0px 2px 6px 3px rgba(74, 74, 74, 0.2);
}

.button {
    display: inline-flex;
    align-items: center;

    :global(.chevron) {
        margin-left: auto;
        position: static !important;
    }
}
