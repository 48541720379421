.mediaListLoading {
    display: flex;
    flex-direction: column;
    height: 222px;

    :global(.placeholder) {
        height: 24px !important;

        &:not(:first-of-type) {
            margin-top: 8px;
        }
    }
}

.Row {
    cursor: pointer;

    &:hover {
        background: rgba(41, 171, 226, 0.05);
    }
}
