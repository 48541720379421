@import "../../assets/style-params.module";

.Card {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 330px;

    & > :global(.section) {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        :global(.name-private) {
            display: flex;

        }

        :global(.name-input) {
            flex: 1;
        }

        :global(.public-company) {
            padding-left: 8px;
        }

        & > label {
            @include font-label-copy-semi-bold();
            margin-bottom: 8px;
            display: inline-block;
        }

        & > input {
            @include font-label-copy-default();
            display: block;
            width: 100%;
            padding: 7px 8px;
            border-radius: 2px;
            border: 1px solid #cfcfcf;
            box-sizing: border-box;
            outline: 0;
            margin: 0;
        }
    }

    :global(.info-message) {
        flex: 0;
        background: #f8eded;
        padding: 16px;
        gap: 8px;
    }

    :global(.footer) {
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        margin-top: auto;
    }
}
