.EntityCellRenderer {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 32px;

    :global(.entity-img) {
        height: 32px;
        width: 32px;
    }

    :global(.entity-name) {
        margin-left: 8px;
    }
}

.Tooltip {
    :global(.tooltipBody) {
        max-width: 190px;
    }
}

.TooltipContentWrapper {
    height: 24px;
}
