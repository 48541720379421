.cardErrorMessage {
    text-align: left;
    width: 498px;

    &:after {
        clear: both;
        content: "";
        display: table;
    }

    img {
        float: left;
        width: 72px;
    }

    > div {
        float: left;
    }

    p {
        float: none;
        margin-top: 8px;
        width: 420px;

        &:first-of-type {
            margin-top: 0px;
        }

        &:global(.description) {
            font-size: 13px;
            line-height: 16px;
        }
    }
}

.button {
    margin-top: 16px;
}
