@import "../../assets/style-params.module";

.SettingsContainer {
    margin: 48px auto;
    width: 1024px;

    h2 {
        font-size: 20px;
        margin: 0;
    }

    :global(.content) {
        background-color: white;
        border-left: 2px solid white;
        height: calc(100vh - 222px);
        margin-top: 16px;
        display: flex;
    }

    :global(.settings-form) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 32px;
        font-size: 13px;
        color: #000000;

        :global(.header-text) {
            font-size: 20px;
            line-height: 24px;
        }

        :global(.divider) {
            border-top: 1px solid $color-gainsboro-gray;
            padding-bottom: 16px;
        }

        :global(.row) {
            width: 100%;
            display: flex;
            flex-direction: row;

            :global(.field) {
                flex: 1;

                &:first-child {
                    margin-right: 8px;
                }
            }
        }

        :global(.field) {
            margin-bottom: 16px;

            button {
                width: 100%;
                border: 1px solid $color-silver-2;
                padding: 8px;
                border-radius: 0;
            }

            label {
                font-family: Arial, sans-serif;
                font-weight: 700;
                display: block;
                margin-bottom: 8px;
            }

            input {
                box-sizing: border-box;
                width: 100%;
                border: 1px solid $color-silver-2;
                padding: 8px;
                font-family: Arial, sans-serif;
            }

            :global(.company-input) {
                border: none;
                color: #000000;
            }

            :global(.picker) {
                height: 32px;
                width: 100%;
                border: 1px solid $color-silver-2;

                :global(.control) {
                    background-color: transparent;
                    width: 100%;
                    border: 1px solid $color-silver-2;
                    border-radius: 0;
                    height: 32px;

                    span {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 16px;
                        padding: 8px;
                        margin-left: 0;
                        border-bottom: 0;

                        img {
                            margin-left: auto;
                            top: 0;
                            height: 24px;
                            width: 24px;
                        }
                    }
                }

                :global(.highlight) {
                    height: 32px;
                    padding-top: 0;

                    :global(.choice) {
                        margin-top: 0;
                        width: 100%;
                        color: $color-black;
                        font-style: normal;
                        font-weight: 400;
                    }
                }
            }
        }

        :global(.messages) {
            font-size: 13px;
            padding: 8px;
            margin-bottom: 16px;

            &:global(.error) {
                background-color: rgba(176, 48, 48, 0.1);
                color: #B03030;

                ul {
                    margin: 0;
                }
            }

            &:global(.success) {
                background-color: rgba(157, 203, 81, 0.1);
                color: #3CB44B;
            }
        }

        :global(.save-btn) {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
        }
    }
}

.SettingsTabs {
    display: flex;
    flex-direction: column;
    min-width: 264px;
    width: 264px;

    span {
        padding-left: 48px !important;

        &:global(.selected) {
            background: #fff;
        }

        &:global(.current) {
            border: none;
            padding-top: 16px;
        }
    }
}

.customButton {
    width: 92px;
}
