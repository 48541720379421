.Dropdown {
    display: flex;
    align-items: center;
    color: #2DA9DE;
    cursor: pointer;

    &.no-padding {
        padding-left: 0px;
    }
    
    span {
        color: white;
    }
}
.SwitchAccount {
    align-items: center;
    text-align: center;
    display: flex;
    background: #FFC600;
    color: black;
    height: 32px;
    padding: 0px 8px;
    border-radius: 2px;
    margin: 0px 8px;
}