.notIdealState {
    margin: 0px auto;
    text-align: center;
    width: 510px;

    img {
        width: 267px;
    }

    p {
        font-size: 24px;
        line-height: 28px;
        margin: 0px;
        margin-top: 40px;
    }
}
