@import "../../assets/style-params.module";

.pills {
    height: 154px;
    overflow: auto;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    margin-top: -8px;

    :global(.selection) {
        border: 1px solid #C9C8C8;
        border-radius: 2px;
        padding: 8px;
        margin: 8px 8px 0 0;
        display: flex;
        align-items: center;

        &:global(.warning){
            background-color: #fff7d8;
        }

        :global(.name) {
            @include font-label-copy-bold();
            display: block;

            :global(.category) {
                max-width: 300px;
            }
        }

        :global(.type) {
            color: #707070;
            font-size: 12px;
            display: block;
            text-transform: capitalize;
        }

        :global(.close) {
            margin-left: 11px;
            cursor: pointer;
        }
    }

    :global(.more) {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
        font-weight: bold;
    }
}

.tooltip {
    &:global(.top) {
        z-index: 10001;
    }
}
