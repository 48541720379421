.localTVNetworksPickerCard {
    box-shadow: 0 2px 6px 3px rgba(74, 74, 74, 0.2);
    padding: 16px;
    height: 398px;
    overflow-y: auto;

    :global(.section) {
        :global(.section-header) {
            font-weight: bold;
            margin-bottom: 16px;
            position: sticky;
            top: -16px;
            background: white;
            height: 32px;
            line-height: 32px;

            &:global(.cable) {
                margin-top: 24px;
            }
        }

        :global(.option) {
            margin-bottom: 8px;

            > div {
                padding-right: 8px;
            }
        }
    }

    .networksListsSection {
        :global(.section-header) {
            margin-bottom: 0px;
            z-index: 2;
        }
    }
}

.treeSelect {
    padding: 0 16px 16px 0px;

    :global(.option) {
        width: 100%;

        > div {
            padding-right: 4px !important;
        }
    }

    :global(.tree-group) {
        margin-bottom: 0px !important;
    }

    :global(.checkbox-parent) {
        height: 24px;
        display: flex;
        align-items: center;
    }
}