@import "../../../../assets/style-params.module";

.cellData {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:global(.change) {
        justify-content: space-between;

        :global(.percentage-change) {
            display: flex;

            img {
                width: 14px;
                height: 14px;
            }

            &:global(.percentage-change):global(.negative) {
                color: $color-cerise;
            }

            &:global(.percentage-change):global(.positive) {
                color: $color-online-media;
            }
        }
    }
}
