@import "../../../../../assets/style-params.module";

.NotificationRow {
    padding-bottom: 16px;

    :global(.date), span {
        @include font-label-copy-semi-bold();
    }

    :global(.link) {
        cursor: pointer;
        text-decoration: underline #dadada;
    }

    > div {
        height: 18px;
    }

    :global(.content) {
        height: 100%;
    }
}
