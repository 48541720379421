.Empty {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    justify-content: flex-start;
    align-items: center;
    flex: 1;

    span, :global(.create-button) {
        margin-top: 15px;
    }

    span p {
        text-align: center;
    }
}
