@import "../../../assets/style-params.module";
@import "../../../assets/mixins.module";

.TagList {
    flex: 1;
    display: flex;
    overflow: auto;
    flex-direction: column;
    @include hideScrollbar();

    :global(.search-box) {
        margin-bottom: 16px;
        width: 100%;
        position: sticky;
        top: 0;
        background: $color-white-solid;
        border-bottom: 1px solid #f6f6f6;
        z-index: 1;
    }

    :global(.search-wrapper) {
        width: 100%;
    }

    :global(.empty-msg) {
        top: 0;
    }

    :global(.company-tag) {
        color: $color-silver;
    }

    :global(.placeholder-container) {
        padding-top: 8px;

        :global(.placeholder-rows) {
            width: 100%;

            :global(.placeholder-row) {
                display: flex;
                height: 16px;
                width: 100%;
                margin-bottom: 8px;

                :global(.placeholder-checkbox) {
                    margin: 0 4px;
                    width: 16px;
                }

                :global(.placeholder-dot) {
                    margin: 0 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 16px;

                    > div {
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                    }
                }

                :global(.placeholder-text) {
                    margin: 0 4px;
                    width: 100%;
                }
            }
        }
    }

    :global(.tag-list) {
        width: 100%;
        height: 100%;

        :global(.tag) {
            height: 30px;
            display: flex;
            align-items: center;

            &:hover {
                background: rgba(41, 171, 226, 0.05);
            }

            > div {
                margin: 0 4px;
            }

            :global(.clickable-row) {
                align-items: center;
                flex: 1;
                cursor: pointer;
                display: flex;

                > div {
                    margin: 0 4px;
                }

                &:global(.disabled) {
                    cursor: not-allowed;
                    opacity: 0.4;
                }
            }

            :global(.color-container) {
                flex-basis: 20px;
                justify-content: center;
                align-items: center;
                display: flex;
            }

            :global(.colored) {
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                &:global(.color) {
                    width: 8px;
                    height: 8px;
                }

                &:global(.sentiment) {
                    width: 20px;
                    height: 20px;

                    :global(.sentiment-img) {
                        height: 14px;
                        width: 14px;
                        filter: brightness(6);
                    }

                    :global(.sentiment-loader-img) {
                        height: 20px;
                        width: 20px;
                        filter: brightness(6);
                    }
                }
            }

            :global(.name) {
                flex: 1;
            }

            :global(.edit) {
                cursor: pointer;
                display: flex;

                img {
                    height: 24px;
                }
            }
        }
    }
}

.Popup {
    width: 200px;
}
