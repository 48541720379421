@import "../../../../assets/style-params.module";

.listsTab {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;

    :global(.placeholder-wrapper) {
        flex: 1 auto;
        gap: 40px;
        display: flex;
        max-height: 16px;
        margin-bottom: 16px;
    }

    :global(.placeholder) {
        height: 16px;
        width: 100%;

        &:global(.small) {
            width: 82px;
        }
    }

    :global(.categories-empty) p {
        margin: 0;
    }

    :global(.empty-msg) {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-bottom: 40px;
        top: unset;

        :global(.message) {
            line-height: 24px;
        }
    }

    :global(.scroll-wrapper) {
        margin-bottom: 0 !important;
    }

    input {
        background: #fff;
        display: inline-block;
        font-size: 13px;
        line-height: 16px;
        outline: 0px;
        padding: 7px 8px;
        border: 1px solid #E0DADE;
        border-radius: 2px;
        color: #303030;
        flex: 1;
    }
}

.TreeSelect {
    :global(.head) {
        position: sticky;
        top: -19px;
        background: $color-white-solid;
        z-index: 2;
        font-weight: bold;

        :global(.option) {
            font-weight: normal;

            :global(.addition) {
                color: $color-dove-grey;
            }
        }
    }
}

.TryAgain {
    color: $color-summer-sky;
    text-decoration: underline;
    cursor: pointer;
}

.WidgetNotIdealState {
    flex: 1;
}
