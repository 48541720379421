@import "../../../../assets/style-params.module";
@import "../mixins.module";

.CreateModal {
    height: 90vh;
    display: flex;
    flex-direction: column;

    :global(.content) {
        @include formViewMixin();
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        flex: 1;

        p {
            margin: 0;
        }

        :global(.bottom) {
            :global(.warning) {
                padding: 16px;
                background-color: RGBA(176, 48, 48, 0.1);
                margin-top: 16px;
                @include font-label-copy-bold();

                :global(.warning-header) {
                    line-height: 16px;
                    font-size: 16px;
                }

                :global(.warning-message) {
                    margin-top: 6px;
                    font-weight: 400;

                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }

            :global(.footer) {
                margin-top: 24px;
                display: flex;
                justify-content: flex-end;

                :global(.cancel-btn) {
                    margin-right: 8px;
                }
            }
        }
    }
}
