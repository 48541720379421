@import "../../../assets/style-params.module";

.MainMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 48px;
    width: inherit;
    padding-right: 8px;

    @media screen and (max-width: 1280px) {
        display: none;
    }

    >div {
        height: 46px;
    }

    :global(.sidebar-icons) {
        margin-left: auto;
        display: flex;
        justify-content: center;
    }
}

.HomepageMenuOptionPickerButton {
    margin-bottom: 2px;
    line-height: 44px;
    font-size: 13px;
    color: #ffffff;
    border-right: 1px solid #000000;
    padding: 0 16px;
    font-weight: normal;

    &:first-of-type {
        border-left: 1px solid #000000;
    }

    &:global(.light-border) {
        border-color: rgba(255, 255, 255, 0.08);
    }

    &:global(.selected), &:hover {
        margin-bottom: 1px;
        line-height: 44px;
        border-bottom: 1.5px solid #ffa400 !important;
    }

    &:hover {
        background-color: #252b39;
    }

    :global(.menu-item) {
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            vertical-align: middle;
        }

        :global(.chevron) {
            line-height: 48px;
            padding-left: 4px;

            img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: inherit;
                height: inherit;
            }
        }
    }
}
