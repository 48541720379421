@import "../../../../../assets/style-params.module";

.dropdownBody {
    position: relative;
    width: 340px;
    height: 256px;
    padding: 16px;
    box-shadow: 0px 2px 6px 3px rgba(74, 74, 74, 0.2);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    :global(.list) {
        flex: 1 1 auto;
        list-style: none;
        padding: 0;
        margin: 0;
        overflow: auto;
        height: 0;

        :global(.option) {
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            padding: 8px;
            color: #303030;
            font-size: 13px;
            line-height: 16px;
            min-height: 32px;

            &:hover {
                background: rgba(41, 171, 226, 0.05);
            }
        }
    }

    :global(.bottom) {
        flex: 0 0 auto;
        display: flex;
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid #F6F6F6;

        input {
            flex: 1 1 auto;
            color: #303030;
            font-size: 13px;
            line-height: 16px;
            outline: 0px;
            padding: 8px;
            border-radius: 2px;
            border: 1px solid #cfcfcf;
            margin-right: 8px;
        }
    }

    :global(.notice) {
        background-color: rgba(252, 219, 177, 0.3);
        font-size: 13px;
        margin-top: 16px;
    }
}
