@import "../../../assets/style-params.module";

.Controls {
    :global(.header-text) {
        font-size: 20px;
        line-height: 24px;
    }

    :global(.control-buttons) {
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-end;
    }
}

.StationSetsTab {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;

    :global(.list) {
        display: flex;
        flex-direction: column;
    }

    :global(.is-visible) {
        padding-right: 10px;
    }

    :global(.station-set) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;

        :global(.edit-button) {
            color: $color-summer-sky;
            cursor: pointer;
        }
    }

    :global(.edit-button) {
        color: $color-summer-sky;
        cursor: pointer;
    }
}
