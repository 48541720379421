@import "../../assets/style-params.module";

.widgetNotIdealState {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;

    :global(.not-ideal-state-wrapper) {
        width: 379px;
        display: flex;
        align-items: center;

        &:after {
            clear: both;
            content: "";
            display: block;
        }

        > img {
            margin-right: 8px;
            width: 111px;
        }

        > div {
            float: left;
            max-width: 260px;

            p {
                color: $color-dove-grey;
                margin: 0px;

                &:first-child {
                    @include font-oops-copy();
                    margin-top: 4px;
                }
            }

            :global(.button) {
                margin-top: 8px;
            }
        }
    }
}
