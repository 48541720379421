@import "../../../assets/style-params.module";

.dropdownBody {
    font-weight: bold;
    position: relative;
    display: flex;
    background-color: $color-curious-blue;
    color: $color-solid-white;
    padding: 10px 11px;
}
