@import "../../../../assets/style-params.module";
@import "../mixins.module";

.EditModal {
    height: 90vh;
    display: flex;
    flex-direction: column;

    :global(.content) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        flex: 1;

        p {
            margin: 0;
        }

        :global(.top) {
            :global(.controllers) {
                display: flex;
                flex-direction: column;
            }
        }

        :global(.add) {
            display: flex;

            :global(.add-picker) {
                flex: 1;
            }

            :global(.add-list-picker) {
                > button {
                    width: 100%;
                }
            }

            :global(.label) {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 24px;

                > label {
                    @include font-label-copy-bold();
                }
            }
        }

        :global(.bottom) {
            :global(.warning) {
                padding: 16px;
                background-color: RGBA(252, 219, 177, 0.3);
                margin-top: 16px;
                @include font-label-copy-bold();

                &:global(.limit), &:global(.errors) {
                    background-color: RGBA(176, 48, 48, 0.1);
                }

                :global(.warning-header) {
                    line-height: 16px;
                    font-size: 16px;
                }

                :global(.warning-message) {
                    margin-top: 6px;
                    font-weight: 400;
                }
            }

            :global(.footer) {
                margin-top: 24px;
                max-height: 32px;
                display: flex;
                justify-content: space-between;

                :global(.left) {
                    display: flex;
                    color: $color-black;

                    :global(.delete-btn) {
                        color: white;
                        @include button-error-type();
                    }

                    :global(.delete-checkbox) {
                        display: flex;
                        align-items: center;

                        :global(.checkbox-icon) {
                            margin: 8px;
                        }
                    }
                }

                :global(.right) {
                    display: flex;

                    :global(.apply-btn) {
                        white-space: nowrap;
                    }

                    :global(.cancel-btn) {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
