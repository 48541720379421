.conditionalNotificationUserInformation {
    width: 512px;
    min-height: 96px;
    margin-top: 18px;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    :global(.data-alert) {
        background-color: rgb(255, 201, 192);
    }

    :global(.product-release) {
        background-color: rgb(193, 232, 197);
    }

    :global(.general-update) {
        background-color: rgb(191, 218, 255);
    }

    :global(.char) {
        width: 8%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :global(.char):global(.data-alert) {
        background-color: rgb(255, 38, 0);

        img {
            scale: 1.6;
        }
    }

    :global(.char):global(.product-release) {
        background-color: rgb(5, 166, 20);

        img {
            scale: 0.5;
        }
    }

    :global(.char):global(.general-update) {
        background-color: rgb(0, 104, 255);

        img {
            scale: 1.7;
        }
    }

    :global(.content) {
        width: 86%;
        display: flex;
        flex-direction: column;
    }

    :global(.content__header) {
        font-size: 16px;
        line-height: 24px;
        padding: 8px 0 8px 8px;
    }
    :global(.content__body) {
        font-size: 14px;
        line-height: 16px;
        padding: 8px 0 8px 8px;
    }

    :global(.close-button) {
        width: 10%;
        padding-top: 10px;

        img {
            display: block;
            padding-left: 16px;
        }

        img:hover {
            cursor: pointer;
        }
    }
}
