.checkbox {
    cursor: pointer;
    display: inline-block;
    height: 16px;
    width: 16px;

    &:global(.disabled) {
        cursor: not-allowed;
    }

    & > img {
        height: 16px;
        vertical-align: top;
        width: 16px;
    }
}
