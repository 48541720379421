.colorList {
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: #ffffff;
    padding: 8px;
    width: 208px;

    :global(.color-choice) {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        margin: 8px;
        cursor: pointer;
    }
}
