.RadioButtonListRow {
    position: relative;
    cursor: pointer;

    &:global(.disabled) {
        cursor: not-allowed;
        color: gray;
    }

    :global(.radio) {
        position: absolute;
    }

    :global(.label) {
        margin-left: 26px;
        line-height: 18px;
    }
}

.Tooltip {
    display: flex;
    align-items: center;
    width: 100%;
}
