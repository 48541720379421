.BaseDateRangeCalendar {
    :global(.react-datepicker) {
        width: 100%;
        border: unset;
        display: flex;
        justify-content: center;

        :global(.react-datepicker__month-container) {
            width: 50%;

            :global(.react-datepicker__header) {
                background-color: unset;
                border-bottom: unset;
                padding: 0;
            }

            :global(.react-datepicker__header--custom) {
                select {
                    border: 1px solid #c9c8c8;
                    font-size: 13px;
                    color: #303030;
                    font-style: normal;
                    font-weight: 400;
                    font-family: Arial, sans-serif;
                    text-align: left;
                    height: 32px;
                    margin: 0 8px 0 0;
                    line-height: 32px;
                    padding: 0 8px;
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }

        :global(.react-datepicker__month-container) {
            width: inherit;

            :global(.react-datepicker__header) > div {
                button {
                    padding: 0 8px;
                    border-radius: 4px;
                    cursor: pointer;
                    border: 1px solid #c9c8c8;
                    width: unset;
                    height: unset;
                }

                button:not(:last-child) {
                    padding: 0 8px;
                    margin: 0 8px;
                }
            }

            :global(.react-datepicker__month) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            :global(.react-datepicker__day-names) {
                display: flex;
                justify-content: center;
                font-weight: 600;
            }
        }

        :global(.react-datepicker__day--in-range), :global(.react-datepicker__day--in-selecting-range), :global(.react-datepicker__day--selected), :global(.react-datepicker__month-text--in-range), :global(.react-datepicker__month-text--in-selecting-range), :global(.react-datepicker__month-text--selected), :global(.react-datepicker__quarter-text--in-range), :global(.react-datepicker__quarter-text--in-selecting-range), :global(.react-datepicker__quarter-text--selected), :global(.react-datepicker__year-text--in-range), :global(.react-datepicker__year-text--in-selecting-range), :global(.react-datepicker__year-text--selected) {
            background-color: #2aabe1;
            border-radius: 2px;
            color: #fff;
        }

        :global(.react-datepicker__day--keyboard-selected), :global(.react-datepicker__month-text--keyboard-selected), :global(.react-datepicker__quarter-text--keyboard-selected), :global(.react-datepicker__year-text--keyboard-selected) {
            background-color: #2aabe1;
            border-radius: 2px;
            color: #fff;
        }
    }
}
