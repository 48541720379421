.barCell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;

    :global(.cell-value) {
        margin-left: auto;
    }

    :global(.bar) {
        overflow: hidden;
        margin-top: 4px;
        background: #d8d8d8;
        height: 10px;
        width: 100%;
        border-radius: 100px;
    }

    :global(.bar-fill) {
        height: 14px;
        background: #777777;
    }
}
