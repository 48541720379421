@import "../../../../assets/animations.module";
@import "../../../../assets/style-params.module";

.MainView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    flex: 1;

    p {
        margin: 0;
    }

    :global(.top) {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        :global(.header) {
            :global(.header-text) {
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 12px;
            }

            :global(.divider) {
                border-top: 1px solid $color-gainsboro-gray;
                margin-bottom: 20px;
            }

            :global(.controllers) {
                display: flex;
                justify-content: flex-end;
            }

            :global(.error) {
                padding: 16px;
                background-color: RGBA(176, 48, 48, 0.1);
                margin-bottom: 8px;
                @include font-label-copy-bold();

                :global(.error-message) {
                    font-weight: 400;
                }
            }
        }
    }

    :global(.categories) {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: 16px 0;
        overflow-y: auto;

        :global(.loader) {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            :global(.loader-img) {
                animation: rotation-left 1s infinite linear;
                overflow: hidden;
                display: flex;
                flex-direction: column;
            }
        }

        :global(.select-all-remove) {
            margin: 0;
            margin-bottom: 16px;
        }

        :global(.spacer) {
            display: none;
        }
    }

    :global(.bottom) {
        :global(.footer) {
            > p {
                color: black;
            }
        }
    }
}
