@import "../../../../assets/style-params.module";

.MoveFolderModalContainer {
    min-height: 208px;
    min-width: 480px;

    :global(.options) {
        padding: 16px 0px;

        :global(.option) {
            margin-top: 6px;
        }
    }

    :global(.close) {
        width: 98px;
    }

    :global(.move) {
        @include button-primary-type();
        width: 120px;
        margin-left: 8px;
    }
}
