@import "../../../../../../assets/style-params.module";

.AdvancedModePicker {
    min-width: 215px;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
    overflow-y: auto;
    background-color: #ffffff;

    :global(.option) {
        padding: 8px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;

        :global(.name) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        :global(.icon) {
            width: 10px;
            padding-left: 16px;

            img {
                width: 10px;
                height: 15px;
            }
        }

        &:hover {
            background: rgba(41, 171, 226, 0.05);
        }

        &:global(.locked) {
            cursor: not-allowed;
        }
    }
}
