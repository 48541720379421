.simplePicker {
    :global(.highlight) {
        width: 168px;
        height: 16px;
        padding: 0;
        margin: 0;
        font-size: 13px;
        line-height: 16px;
        color: #303030;
        background-color: unset;
        text-align: left;
        border: unset;

        span {
            margin: unset;
            padding-left: 8px;
        }

        :global(.choice), img {
            top: 3px;
        }
    }
}

.dropdown {
    width: 136px;
    padding: 16px;

    :global(.options) {
        margin: 0;
        padding: 0;

        a {
            padding: 8px;
        }

        a:global(.active) {
            color: #58CDFF;
        }

        a:global(.disabled) {
            user-select: none;
            cursor: not-allowed;
            color: #c3c3c3 !important;

            &:hover {
                background-color: unset !important;
            }

            &:global(.focused) {
                background-color: unset !important;
            }
        }
    }
}
