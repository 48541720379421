.ChoiceList {
    box-sizing: border-box;
    box-shadow: 0 0 4px 2px rgba(64, 64, 66, 0.2);
    min-width: 215px;
    padding: 16px;
    max-height: 30vh;
    overflow-y: auto;

    :global(.search-box) {
        margin-top: 0;
        margin-bottom: 16px;
        width: 100%;
    }

    :global(.option) {
        padding: 8px;
        line-height: 16px;
        cursor: pointer;

        &:hover {
            background: rgba(41, 171, 226, 0.05);
        }
    }
}
