@import "../../assets/style-params.module";

.CustomButtonContent {
    cursor: pointer;
    color: #303030;
    display: flex;
    align-items: center;
    height: 48px;

    img {
        vertical-align: top;
        transition: transform 300ms ease-in;

        &:global(.rotated) {
            transform: rotate(180deg);
        }
    }

    span {
        border-bottom: 2px solid #F6F6F6;
        display: inline-block;
        height: 22px;
        line-height: 22px;
        margin-left: 16px;

        &:global(.disabled) {
            cursor: not-allowed;
        }

        img {
            margin-left: 2px;
            position: relative;
            top: 1px;
            width: 16px;
        }
    }

    :global(.choice) {
        :global(.menu) {
            background-color: unset;
            border: 1px solid #fff;
            border-radius: 50%;
        }
    }
}

.CustomHeader {
    font-size: 13px;

    :global(.options) {
        box-sizing: content-box;
    }

    :global(.choice) {
        height: 56px;
        padding: 15px 0 7px;
        display: flex;

        :global(.initials) {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: unset;
            border: 1px solid $color-curious-blue;
            color: $color-curious-blue;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            line-height: 30px;
            margin: 0 8px 0 0;
        }

        :global(.multirowName) {
            font-weight: 600;
            line-height: 32px;
        }
    }

    :global(.separator) {
        width: calc(100% - 32px);
        height: 1px;
        margin: 0 16px;
        background: #F6F6F6;
    }
}
