@import "../../../../assets/style-params.module";

.widthSizerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 0 8px 0;
    height: 24px;

    &:hover {
        :global(.column):first-child {
            background: $color-summer-sky;
            color: $color-summer-sky;
            border: none;
        }
    }

    :global(.column) {
        background: $color-culture-white;
        color: $color-culture-white;
        cursor: pointer;
        margin: 0 8px 0 0;
        border-radius: 2px;
        transition: all 200ms ease-in-out;

        &:first-child {
            background: $color-white-solid;
            color: $color-white-solid;
            border: 1px solid $color-silver-2;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    :global(.column-25) {
        width: 25%;
    }

    :global(.column-33) {
        width: 33%;
    }

    :global(.column-50) {
        width: 50%;
    }

    :global(.column-75) {
        width: 75%;
    }

    :global(.column-100) {
        width: 100%;
    }
}
