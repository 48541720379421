@import "../../../../assets/style-params.module";

.listControls {
    justify-content: space-between;

    :global(.option) {
        width: 50%;
    }
}

.emptyState {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
    padding: 15px;

    :global(.empty-msg) {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-bottom: 1px solid #E0DADE;
        padding-bottom: 40px;
        top: unset;

        :global(.message) {
            line-height: 24px;
        }
    }

    button {
        margin-top: 15px;
    }
}

.addButton {
    width: 100%;
}

.control {
    width: 50%;

    button:global(.dropdown) {
        color: $color-black;
        border-color: $color-silver-2;
        width: 100%;
        padding-left: 8px;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }
}
