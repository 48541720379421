@import "../../../assets/style-params.module";

.ManageCategoriesTab {
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
