@import "../../../../../assets/style-params.module";
@import "../../../../../assets/mixins.module";

$indent: 16;

@mixin leaf() {
    @include onListElementHover();
    display: flex;
    align-items: center;
    height: unset !important;
    min-height: 32px;
    margin-bottom: 6px;

    span {
        padding-left: 8px;
    }
}

.audiencePmxTree {
    padding-left: #{$indent}px;

    &.category {
        padding-left: 30px;
    }

    &:not(:first-of-type) {
        margin-top: 4px;
    }

    .chevron {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        flex-shrink: 0;

        &.chevronOpen {
            transform: rotate(90deg);
        }
    }

    .spinningLoader {
        animation: rotation-left 1s infinite linear;
        margin-left: #{$indent}px;
    }

    .groupRow {
        @include onListElementHover();
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        height: 32px;

        span {
            cursor: pointer;
        }
    }

    .categoryChild {
        margin-left: -18px;

        &:before {
            border-left: 2px dotted $color-silver;
            border-bottom: 2px dotted $color-silver;
            content: '';
            height: 18px;
            width: 14px;
            top: -10px;
            margin-right: 4px;
            position: relative;
        }
    }

    .categoryCheckbox {
        span {
            padding-left: 8px;
        }
    }

    .categoryRadio {
        align-items: center;
    }

    .leafRow {
        @include leaf();
        padding-left: #{$indent - 4}px;

        &:before {
            border-left: 2px dotted $color-silver;
            border-bottom: 2px dotted $color-silver;
            content: '';
            height: 18px;
            width: 14px;
            top: -10px;
            margin-right: 4px;
            position: relative;
        }
    }
}

.audiencePmxSimpleMultiTab {
    margin-left: -#{$indent}px;
    display: flex;
    flex-direction: column;

    .savedCustomTargets {
        padding: 0 0 0 16px;
    }

    .topContent {
        display: flex;
        align-items: center;
        padding: 16px 0 16px 16px;

        .searchBox {
            width: 100%;
            margin-top: 0;
        }
    }

    .divider {
        padding: 0 16px;
    }

    .trees {
        position: relative;
        overflow: auto;
        height: 100%;
        display: flex;
        flex-direction: row-reverse;

        .treeTargets {
            flex-grow: 1;

            .leafRow {
                @include leaf();
                padding-left: 40px;
            }
        }

        .createTargetButton {
            position: absolute;
            top: 0;
            right: 0;
        }
    }
}

.divider {
    padding-left: 20px !important;
}

.selectionsList {
    margin-top: auto;
}

.divider {
    padding-left: 20px !important;
}

.selectionsList {
    margin-top: auto;
}

.pillsWrapper {
    padding: 0 16px;

    :global(.divider) {
        height: 1px;
        width: 100%;
        border-top: 1px solid $color-gainsboro-gray;
        margin-bottom: 16px;
    }

    :global(.targets-list) {
        display: flex;
        align-items: center;
        margin-top: auto;

        :global(.clear-targets) {
            font-size: 13px;
            color: $color-summer-sky;
            cursor: pointer;
            margin-left: 16px;
            user-select: none;
        }

        :global(.targets-pills) {
            height: fit-content;
            max-height: 64px;
            overflow-y: auto;
            width: 100%;

            &:global(.without-closing) {
                :global(.selection) {
                    padding: 0 9px;
                }
            }

            :global(.selection) {
                border-radius: 4px;
                padding: 0 0 0 9px;
                background-color: #BCE6F8;
                border: 0;

                & > div {
                    :global(.type) {
                        display: none;
                    }

                    :global(.name) {
                        @include font-supplimentary-copy();
                        line-height: 24px;
                        color: $color-bunker;
                    }
                }
            }
        }
    }
}
