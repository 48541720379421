.tvSpecificBroadcastMonthPicker {
    display: flex;
    align-items: center;

    :global(.picker) {
        display: flex;
        flex-direction: column;
        label {
            font-weight: bold;
        }
        &:first-of-type{
            margin-right: 16px;
        }
    }
}
