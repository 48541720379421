@import "../../../assets/style-params.module";

.SideBarContainer {
    height: 100vh;
    background: $color-white-solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    position: absolute;
    right: 0;
    z-index: 999;
    width: 208px;
    transition: 0.5s ease-in;
    margin-top: 44px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);

    > div {
        width: 100%;

        button {
            width: 100%;

            > div {
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 1281px) {
        display: none;
    }

    :global(.icons) {
        width: 100%;

        > div {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    hr {
        width: 100%;
        border: 1px solid $color-grey-side;
    }

    :global(.choice) {
        :global(.menu) {
            border: 1px solid black;
            color: $color-black-solid;
        }
    }

    :global(.button), :global(.label) {
        svg {
            filter: invert(100%);
        }
    }

    :global(.extras) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;

        > div {
            margin: 0;
        }
    }
}

.HomepageMenuOptionPickerButton {
    cursor: pointer;
    padding: 16px;
    width: 100%;
    color: #000;
    font-weight: 600;
    text-align: left;
    box-sizing: border-box;
    justify-content: start;
    font-size: 13px;
    height: 48px;

    &:global(.selected), &:hover {
        background: rgba(41, 171, 226, 0.05);
    }
}
