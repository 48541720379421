@import "../../../../assets/style-params.module";

.topContent {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    .searchBoxWrapper {
        width: 100%;
        margin-top: 0;
    }

    .networksDropdownButton {
        width: 100%;
        height: 34px;

        & > :global(.children-wrapper) {
            display: block !important;
        }
    }

    .divider {
        width: 100%;
        height: fit-content;
        position: relative;

        &__label {
            text-align: center;
            font-weight: bold;
            position: absolute;
            top: 50%;
            left: 50%;
            padding: 1px;
            transform: translate(-50%, -50%);
            background-color: white;
        }
    }
}

.treeLabel {
    display: block;
    font-weight: bold;
    padding-left: 16px;
    padding-bottom: 8px;
}

.treeContent {
    height: 300px;
    overflow-y: scroll;
    flex: 1;

    :global(.branch) {
        &:global(.leaf) {
            height: 32px;
            line-height: 16px;
        }
    }

    & + :global(.footer) {
        box-shadow: 0 -4px 21px 0 rgba(0, 0, 0, 0.08);
    }

    :global([role=radio]) {
        display: flex;
        align-items: center;
        height: 18px;
    }
}

.infoMessage {
    padding: 0 16px;

    div {
        background: rgba(252, 219, 177, 0.3);
        padding: 16px;
        box-sizing: border-box;
    }
}

.placeholders {
    box-sizing: border-box;
    width: 436px;
    height: 456px;
    padding: 16px;

    :global(.placeholder) {
        height: 24px;
        margin-bottom: 8px;
    }
}

.treeSelect {
    padding: 0 16px 16px 16px;

    :global(.branch)>:global(.branch-items) {
        margin-left: 28px;
    }
}

.clear {
    height: auto;
    padding-top: 4px;
    padding-bottom: 16px;
    color: $color-summer-sky;
    margin-left: auto;
    width: 52px;
    cursor: pointer;
}
