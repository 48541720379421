@import "../../../../../assets/style-params.module";

.ConnectSocialPagesNotificationRow {
    padding-bottom: 16px;

    :global(.date) {
        @include font-label-copy-semi-bold();
    }

    :global(.pages) {
        margin: 8px 0px;

        > div {
            margin-bottom: 4px;
            margin-left: 6px;
            padding-left: 14px;
            position: relative;

            &:before {
                background: black;
                border-radius: 4px;
                content: "";
                height: 6px;
                left: 0px;
                position: absolute;
                top: calc(50% - 3px);
                width: 6px;
            }
        }
    }
}
