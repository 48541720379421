@import "../../assets/style-params.module";

.unsupportedBrowserMessage {
    height: 68px;
    overflow: hidden;
    position: relative;

    :global(.card) {
        box-shadow: 0px 2px 4px rgba(64, 64, 66, 0.5);
        margin: 0px 8px;
        position: relative;
        top: 0px;
        transition: top 300ms;

        > div {
            display: flex;

            img {
                height: 24px;
                margin-right: 16px;
                width: 24px;
            }

            p {
                height: 24px;
                line-height: 24px;
                margin: 0px;

                &:first-of-type {
                    flex: 1;
                    margin-right: 16px;
                }

                a, span {
                    color: $color-dove-grey;
                    cursor: pointer;

                    &:first-child {
                        color: $color-curious-blue;
                        margin-right: 8px;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &:global(.dismissed) {
        :global(.card) {
            top: -68px;
        }
    }

    @media screen and (max-width: 1279px) {
        :global(.card) {
            > div {
                p {
                    &:first-of-type {
                        line-height: 12px;
                    }
                }
            }
        }
    }
}
