@import "../../../../assets/style-params.module";

.CreateFolderModalContainer {
    height: 208px;
    width: 480px;

    :global(.input) {
        width: 100%;
        margin-top: 8px;
        padding: 8px 4px;
        box-sizing: border-box;
    }

    :global(.label) {
        font-weight: bold;
    }

    :global(.message) {
        margin-bottom: 22px;
    }

    :global(.close) {
        width: 98px;
        min-height: 32px;
    }

    :global(.create) {
        @include button-primary-type();
        width: 120px;
        margin-left: 8px;
        min-height: 32px;
    }
}
