.Button {
    display: flex;
    flex-direction: row !important;
    position: relative;
    width: 100%;
    max-width: 150px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.Dropdown {
    padding: 16px;
    position: absolute;
    right: 15px;
    top: 103px;
    background: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);

    :global(.header) {
        margin-bottom: 8px;
        font-weight: bold;
    }

    &:global(.hidden) {
        display: none;
    }
}
