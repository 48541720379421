@import "../../../../../assets/style-params.module";
@import "../../../../../assets/mixins.module";

@mixin action() {
    margin-right: 24px;
    cursor: pointer;
    text-decoration: underline;
    color: $color-curious-blue;
}

.savedCustomTargetsListWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.isOpen {
        .treeNode svg {
            transform: rotate(90deg);
        }
    }

    .treeNode {
        @include onListElementHover();
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-bottom: 4px;

        svg {
            transition: all 0.3s ease-in-out;
        }

        .treeNodeLabel {
            height: 32px;
            display: flex;
            align-items: center;
        }

        .createTargetButton {
            margin-left: auto;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;

        .savedCustomTarget {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 24px;
            margin-top: 8px;

            .name {

            }

            .action {
                @include action();
            }
        }
    }

    :global(.option) {
        align-items: center;
    }
}

.selectableRowClassName {
    padding-left: 24px;

    &:hover {
        background: rgba(41, 171, 226, 0.05);
    }
}

.selectableCheckbox {
    margin-right: 8px;
}

.choiceList {
    box-sizing: unset;
    box-shadow: none;
    border: none;
    padding: 0 0 16px 0;

    :global(.option) {
        padding: 0 0 2px 0;
        align-items: center;
    }
}

.customRow {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 0 8px 12px;
    height: 16px;

    &:hover {
        background: rgba(41, 171, 226, 0.05);
    }

    &:before {
        border-left: 2px dotted $color-silver;
        border-bottom: 2px dotted $color-silver;
        content: '';
        height: 18px;
        width: 14px;
        top: -10px;
        margin-right: 4px;
        position: relative;
    }

    .label {
        padding-left: 8px;
    }

    .editButton {
        @include action();
        text-align: right;
        margin-left: auto;
        user-select: none;
    }
}
