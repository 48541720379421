.MetricPickerLoadingState {
    display: flex;
    flex-direction: column;
    flex: 1;

    :global(.placeholder-label) {
        max-height: 16px;
        width: 40%;
        margin-bottom: 16px;
        flex: 1;
    }

    :global(.placeholder-description) {
        flex: 4;
        min-height: 80px;
        width: 100%;
        margin-bottom: 16px;

        &:last-of-type {
            margin-bottom: 8px;
        }
    }
}
