@import "../../../assets/style-params.module";

.WidgetLockedDataMessage {
    :global(.not-ideal-state-wrapper) {
        align-items: center;
        display: flex;
        justify-content: center;

        > img {
            width: 32px;
            margin-right: 16px;
        }

        >div {
            width: 276px;

            p:nth-of-type(1) {
                margin-bottom: 8px;
            }

            p:nth-of-type(2) {
                color: $color-tundora;
                text-align: justify;
            }
        }
    }
}
