@import "../../assets/animations.module";

.ForceExtended {
    padding-left: 8px !important;
}
.row {
    display: flex;
    flex-direction: column;

    :global(.header) {
        height: 32px;
        display: flex;
        align-items: center !important;

        :global(.parent-list-row) {
            padding: 0 !important;
            align-items: center;
            width: 100%;
            cursor: pointer;
        }
    }

    :global(.icon-holder) {
        position: relative;
        width: 24px;
        height: 24px;
        display: inline-block;
    }

    img:global(.icon) {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        cursor: pointer;
        opacity: 0;
        display: inline-block;
        transition-duration: 0.1s;
    }

    img:global(.icon):global(.open) {
        transform: rotateZ(0);
    }

    img:global(.icon):not(:global(.open)) {
        transform: rotateZ(-90deg);
    }

    img:global(.visible) {
        opacity: 1;
    }

    img:global(.loader-icon):global(.visible) {
        animation: rotation 1s linear infinite reverse;
    }

    & > :global(.option) {
        display: inline-block;
    }

    :global(.nested-list-wrapper) {
        display: flex;
        height: 100%;
        position: relative;
        padding-left: 32px;

        &::before {
            border-left: 1px dotted #cbcbcb;
            height: calc(100% - 16px);
            content: '';
            display: inline-block;
            position: absolute;
        }

        :global(.nested-list) {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 100%;

            :global(.separated) {
                padding-right: 8px;
            }

            :global(.option) {
                padding: 8px 8px 8px 0;
                width: 100%;
            }

            :global(.nested-row) {
                display: flex;
                align-items: center;

                &::before {
                    width: 15px;
                    border-bottom: 1px dotted #cbcbcb;
                    content: '';
                    display: inline-block;
                }
            }
        }
    }
}
