.cardNotIdealState {
    text-align: left;
    width: 370px;

    &:after {
        clear: both;
        content: "";
        display: table;
    }

    img {
        float: left;
        margin-right: 16px;
        width: 112px;
    }

    p {
        float: left;
        margin-top: 16px;
        width: 242px;
    }
}
