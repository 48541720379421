@import "../../../../assets/style-params.module";

.Dropdown {
    height: 32px;
    box-sizing: border-box;
    padding-top: 4px;
}

.Icon {
    cursor: pointer;

    &:hover {
        path:last-of-type {
            fill: $color-curious-blue;
        }
    }
}
