.MetricsDefinitionsModal {
    display: flex;
    flex-direction: column;

    :global(.search-box) {
        width: 100%;
        height: 35px;
    }

    :global(.metrics-picker) {
        display: flex;

        :global(.dropdown-button) {
            flex: 1;

            :global(.dropdown) {
                display: flex;
            }
        }
    }

    :global(.descriptions) {
        overflow-y: auto;
        line-height: 16px;
        margin-top: 16px;
        flex: 1;

        :global(.label) {
            font-weight: 600;
        }

        :global(.description) {
            margin-top: 8px;
            margin-bottom: 16px;
        }
    }
}
