.SearchBoxWrapper {
    width: 100%;
    max-width: 468px;
    display: flex;
    padding: 15px 15px 0;
    box-sizing: border-box;

    :global(.search-box) {
        margin: 0 !important;
        border-right: 0;

        input {
            border-radius: 2px 0 0 2px;
            border-right: 0;
        }
    }

    :global(.categories-sets-button) {
        height: 34px;
        border-radius: 0 3px 3px 0;
        max-width: 200px;
    }
}

.CategoriesSetsDropdownContent {
    min-width: auto;
    max-width: 200px;
}
