@import "../../../assets/animations.module";
@import "../../../assets/style-params.module";

.container {
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;

    :global(.header-text) {
        font-size: 20px;
        line-height: 24px;
    }

    :global(.btn) {
        display: flex;
        justify-content: flex-end;
    }

    :global(.list-wrapper) {
        overflow: hidden;
    }
}

.loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        animation: rotation-left 1s infinite linear;
    }
}

.brandTree {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 32px;

    :global(.header-text) {
        font-size: 20px;
        line-height: 24px;
    }

    :global(.btn) {
        display: flex;

        button {
            margin-left: auto;
        }
    }

    :global(.brand-tree) {
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
    }

    :global(.link-border) {
        width: 16px;
        display: flex;
        flex-direction: column;
        margin-top: 1.5px;
        min-width: 16px;
    }

    :global(.top-border) {
        border-left: 1.5px dotted #cbcbcb;
        border-bottom: 1.5px dotted #cbcbcb;
        width: 50%;
        flex: 1;
    }

    :global(.bottom-border) {
        flex: 1;
        margin-top: 1.5px;
    }

    :global(.head) {
        min-height: 34px;
        display: flex;
        align-items: center;
        position: relative;
        right: 1.5px;
        padding-bottom: 4px;
        padding-left: 40px;
        color: $color-tundora;

        &:global(.disabled) {
            color: $color-silver;
        }
    }

    :global(.branches) {
        display: flex;
        flex-direction: column;

        :global(.branch) {
            margin-left: 38px;
            display: flex;

            &:not(:last-child) :global(.bottom-border) {
                border-left: 1.5px dotted #cbcbcb;
            }
        }

        :global(.label) {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            color: $color-tundora;

            &:global(.disabled) {
                color: $color-silver;
            }

            img:global(.icon) {
                padding-right: 8px;
                width: 32px;
            }
        }
    }
}
