@import "../../../../assets/style-params.module";

.container {
    color: #000000;
    font-family: Arial, sans-serif;
    letter-spacing: 0;
    width: 568px;
    padding: 24px;
    box-shadow: 0 2px 4px 0 rgba(64, 64, 66, 0.5);
    border: 1px solid $color-alabaster;
    border-radius: 2px;
    background-color: #FFF;

    :global(.header) {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 1px solid #E2E4E6;
        padding-bottom: 23px;

        :global(.text) {
            display: flex;
            flex-direction: column;
            margin-left: 20px;
        }

        :global(.text__title) {
            height: 24px;
            width: 376px;
            font-size: 24px;
            line-height: 24px;
        }

        :global(.text__subtitle) {
            margin-top: 10px;
            height: 32px;
            width: 376px;
            font-size: 13px;
            line-height: 16px;
        }
    }

    :global(.header__icon) {
        height: 71px;
    }

    :global(.header__close) {
        margin-left: auto;
        cursor: pointer;
        user-select: none;
    }

    :global(.content) {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #E2E4E6;
        color: $color-black;
        font-size: 13px;

        ul {
            font-size: 13px;
            letter-spacing: 0;
            line-height: 24px;
            list-style: none;
            padding-left: 5px;

            li {
                display: block;
            }

            li:before {
                content: "";
                width: 14px;
                height: 10px;
                display: inline-block;
                margin-right: 10px;
                background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2214%22%20height%3D%2210%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Cdefs%3E%3Cpath%20d%3D%22M7.138%2011.357c-.26-.27-.877-.27-1.138.165-.261.435-.26%201.163%200%201.434l3.529%203.841c.26.27.682.27.942%200L18%208.827c.26-.27.26-1.14%200-1.41s-.878-.485-1.138-.214L10%2014.329l-2.862-2.972z%22%20id%3D%22a%22%2F%3E%3C%2Fdefs%3E%3Cuse%20fill%3D%22%23002032%22%20xlink%3Ahref%3D%22%23a%22%20transform%3D%22translate(-5%20-7)%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
                background-size: cover;
            }
        }
    }

    :global(.content__header) {
        height: 16px;
        font-weight: 600;
        line-height: 16px;
    }

    :global(.checkbox) {
        margin-top: 15px;
        margin-bottom: 5px;

        label {
            cursor: pointer;

            div {
                height: unset;
            }

            :global(.right-margined) {
                margin-right: 8px;
                vertical-align: middle;
            }
        }
    }

    :global(.footer) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        :global(.button) {
            margin-left: auto;
        }

        :global(.button__accept) {
            align-self: flex-end;
            color: #FFF;
            background-color: $color-curious-blue;
        }

        :global(.button__close) {
            align-self: flex-end;
            color: $color-curious-blue;
            background-color: #FFF;
            margin-top: 24px;
        }
    }

    :global(.column-direction) {
        flex-direction: column;

        > label {
            align-self: flex-start;
        }

        > textarea {
            font-family: Arial, sans-serif;
            align-self: center;
            width: 100%;
            height: 144px;
            margin-top: 10px;
            margin-bottom: 15px;
            border: 1px solid #C9C8C8;
            border-radius: 2px;
            background-color: #FFF;
            resize: none;
            outline: none;
        }
    }
}
