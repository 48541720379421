.DropdownButtonClassName {
    text-decoration: none;
    white-space: nowrap;
    background: transparent;
    border: none;
    cursor: pointer;
    height: auto;
    padding: 0;

    &:global(.selected), &:hover {
        background: transparent;
    }
}
