@import "../../assets/style-params.module";

.alert {
    margin: 8px auto;
    padding: 8px 16px;
    text-align: center;
    width: fit-content;

    :global(.bold) {
        font-weight: 700;
    }

    :global(.link) {
        cursor: pointer;
    }
}
