@import "../../../assets/style-params.module";

.menuLink {
    cursor: pointer;
    display: block;
    padding: 16px;
    line-height: 16px;
    font-size: 13px;
    color: #ffffff;
    font-weight: 600;
    text-decoration: none;
    white-space: nowrap;

    &:global(.selected), &:hover {
        background-color: $color-very-dark-blue;
    }

    &:global(.logo) {
        padding: 12px 16px;
        background: transparent;

        img {
            width: 24px;
        }
    }
}
