@import "../../assets/style-params.module";

.toggle {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 16px;

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + :global(.slider) {
            background-color: $color-asparagus;
        }

        &:focus + :global(.slider) {
            box-shadow: 0 0 1px $color-asparagus;
        }

        &:checked + :global(.slider):before {
            transform: translateX(16px);
        }
    }

    :global(.slider) {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-dove-grey;
        transition: 0.4s;

        &:before {
            position: absolute;
            content: "";
            height: 14px;
            width: 14px;
            left: 1px;
            top: 0.5px;
            background-color: white;
            transition: 0.4s;
        }
    }

    :global(.round) {
        border-radius: 34px;

        &:before {
            border-radius: 50%;
        }
    }
}
