.dropdownButton {
    height: 32px;
    border: 1px solid #707070;

    :global(.blob) {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-right: 4px;
    }
}
