@import "../../../../assets/style-params.module";

.Controls {
    :global(.header-text) {
        font-size: 20px;
        line-height: 24px;
    }

    :global(.limit-msg) {
        margin-top: 24px;
    }

    :global(.control-buttons) {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
    }
}
