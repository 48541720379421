@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
        -webkit-transform: scale3D(1, 1, 1);
        transform: scale3D(1, 1, 1);
    }

    35% {
        -webkit-transform: scale3D(0, 0, 1);
        transform: scale3D(0, 0, 1);
    }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.TemplateDashboardLoader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    opacity: 0;
    animation: fadeIn 3s forwards;

    :global(.sk-cube-grid) {
        width: 56px;
        height: 56px;
        margin: 100px auto;
    }

    :global(.sk-cube-grid) :global(.sk-cube) {
        width: calc(33% - 4px);
        height: calc(33% - 4px);
        background-color: #ED9A2D;
        margin: 2px;
        float: left;
        -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
        animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    }

    :global(.sk-cube-grid) :global(.sk-cube1) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    :global(.sk-cube-grid) :global(.sk-cube2) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    :global(.sk-cube-grid) :global(.sk-cube3) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }

    :global(.sk-cube-grid) :global(.sk-cube4) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }

    :global(.sk-cube-grid) :global(.sk-cube5) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }

    :global(.sk-cube-grid) :global(.sk-cube6) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }

    :global(.sk-cube-grid) :global(.sk-cube7) {
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
    }

    :global(.sk-cube-grid) :global(.sk-cube8) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }

    :global(.sk-cube-grid) :global(.sk-cube9) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
}
