@import "../../assets/style-params.module";

.dropdownWrapper {
    position: absolute;
    z-index: 2002;

    & > :global(.dropdown) {
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.childrenWrapper {
    display: inline-block;
}

.animationTop {
    animation: fadeInUp 0.3s;
}

.animationRight {
    animation: fadeInLeft 0.3s;
}

.animationBottom {
    animation: fadeInBottom 0.3s;
}

.animationLeft {
    animation: fadeInRight 0.3s;
}

@mixin animationFrom($fromX, $fromY) {
    opacity: 0;
    transform: translate3d($fromX, $fromY, 0);
}

@mixin animationTo() {
    opacity: 1;
    transform: translate3d(0, 0, 0);
}

@keyframes fadeInUp {
    from {
        @include animationFrom(0, 20px);
    }

    to {
        @include animationTo();
    }
}

@keyframes fadeInLeft {
    from {
        @include animationFrom(50%, 0);
    }

    to {
        @include animationTo();
    }
}

@keyframes fadeInRight {
    from {
        @include animationFrom(-50%, 0);
    }

    to {
        @include animationTo();
    }
}

@keyframes fadeInBottom {
    from {
        @include animationFrom(0, -20px);
    }

    to {
        @include animationTo();
    }
}
