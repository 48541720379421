.Card {
    height: 364px;
    width: 360px;
    box-shadow: 0 2px 6px 3px rgba(74, 74, 74, 0.2);

    :global(.search-container-wrapper) {
        width: unset;
    }

    :global(.impersonations-count) {
        padding: 8px;
        margin-top: 16px;
        font-weight: bold;
    }

    :global(.impersonations-list) {
        overflow-y: auto;
        height: calc(100% - 90px);
        padding-left: 8px;

        >div {
            padding: 8px 0;
            cursor: pointer;
        }
    }
}
