@import "../../../../assets/style-params.module";

.TVDaypartsPickerCard {
    box-shadow: 0 2px 6px 3px rgba(74, 74, 74, 0.2);
    height: 512px;
    width: 464px;
    padding: 0;

    :global(.selected-tab) {
        box-sizing: border-box;
        height: 512px;
    }

    .bookmark {
        margin-left: 8px;
    }
}

.Footer {
    box-shadow: 0 -4px 21px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    padding: 16px;
}
