@import "../../../assets/animations.module";
@import "../../../assets/style-params.module";

.container {
    width: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;

    .headerText {
        font-size: 20px;
        line-height: 24px;
    }

    .list {
        display: flex;
        flex-direction: column;
    }

    .dashboard{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
    }

    .stopNotifications{
        color: $color-red;
        cursor: pointer;
    }

}


.StopNotificationModalContainer {
    height: 208px;
    width: 480px;

    .message {
        margin-bottom: 22px;
    }

    .close {
        width: 98px;
    }

    .stopNotification {
        @include button-error-type();
        width: 140px;
        margin-left: 8px;
    }
}

