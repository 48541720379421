@import "../../assets/style-params.module";

.baseWidget {
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    border: 1px solid #CFCFCF;

    > :global(.content) {
        background-color: white;
        flex: 1;
        padding: 8px;
        overflow: hidden;

        > * {
            font-size: 13px;
            line-height: 16px;
            margin: 0px;
        }
    }

    :global(.header) {
        background: #ffffff;
        align-items: center;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        display: flex;
        padding: 0px 8px;
        height: 48px;
        flex: 0 0 auto;

        :global(.floatingArea) {
            width: 200px;
            display: flex;
            position: absolute;
            right: 20px;
            z-index: 3;
            height: 55px;
            padding: 0 4px;

            &:hover ~ :global(.buttons) {
                :global(.hidden) {
                    display: initial;
                }
            }
        }

        :global(.data-source) {
            cursor: pointer;
            padding-right: 8px;
            width: 24px;
            height: 24px;

            img {
                width: 24px;
                height: 24px;
            }
        }

        :global(.header-content) {
            flex: 1;

            > p {
                font-size: 13px;
                line-height: 16px;
                margin: 0px;
            }
        }

        :global(.buttons) {
            min-width: 24px;
            display: flex;
            background: #fff;
            border-radius: 16px;
            padding: 0 4px;

            :global(.button) {
                cursor: pointer;
                height: 24px;
                vertical-align: top;
                width: 24px;
                padding: 4px;

                &:hover {
                    color: #29ABE2;
                    stroke: #29ABE2;
                }
            }

            :global(.hidden) {
                display: none;
            }

            :global(.floating) {
                display: flex;
                background: #fff;
                border-radius: 16px;
                padding: 0 4px;
                position: absolute;
                right: 20px;
                z-index: 4;
                -webkit-box-shadow: 0px 1px 3px 1px rgba(135, 135, 135, 0.5);
                -moz-box-shadow: 0px 1px 3px 1px rgba(135, 135, 135, 0.5);
                box-shadow: 0px 1px 3px 1px rgba(135, 135, 135, 0.5);

                > div {
                    border-radius: 50%;
                }
            }

            :global(.forceOpen) {
                :global(.hidden) {
                    display: initial;
                }
            }

            &:global(.placeholder) {
                width: 32px;
                height: 32px;
                background: transparent;
            }

            &:hover {
                :global(.hidden) {
                    display: initial;
                }
            }
        }
    }

    :global(.footer) {
        background: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        min-height: 16px;

        &:global(.has-content) {
            align-items: center;
            display: flex;
            padding: 0 16px;
            min-height: 48px;
            flex: 0 0 auto;
        }

        :global(.footer-content) {
            color: $color-dove-grey;
            flex: 1;

            > div {
                font-size: 11px;
                line-height: 16px;
            }
        }
    }

    :global(.show-only-on-screen-shot) {
        display: none;
    }

    &:global(.sh__is-screenshotting) {
        box-shadow: none !important;

        :global(.hide-on-screen-shot) {
            display: none;
        }

        button {
            border: none !important;
        }

        :global(.show-only-on-screen-shot) {
            display: inline;
        }
    }
}

.TooltipContentWrapper {
    font-weight: inherit;
    color: #FFFFFF;
    text-decoration: none;
    max-width: 400px;
    word-break: break-all;
}
