@import "../../assets/style-params.module";

.dropdown {
    :global(.option) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover, &:global(.focused) {
            background-color: rgba(41, 171, 226, 0.05);
        }
    }

    :global(.option__lock-icon) {
        height: 13px;
        width: 10px;
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%2214%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8.75%204.375h-.625v-1.25a3.126%203.126%200%2000-6.25%200v1.25H1.25c-.688%200-1.25.563-1.25%201.25v6.25c0%20.688.563%201.25%201.25%201.25h7.5c.688%200%201.25-.563%201.25-1.25v-6.25c0-.688-.563-1.25-1.25-1.25zM5%2010c-.688%200-1.25-.563-1.25-1.25%200-.688.563-1.25%201.25-1.25.688%200%201.25.563%201.25%201.25C6.25%209.438%205.687%2010%205%2010zm1.938-5.625H3.063v-1.25A1.94%201.94%200%20015%201.187a1.94%201.94%200%20011.938%201.938v1.25z%22%20fill%3D%22%23404040%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
    }

    :global(.option__label-dimmed) {
        color: $color-dove-grey;
        font-size: 13px;
    }

    :global(.option__export-lock-icon) {
        height: 24px;
        width: 20px;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cdefs%3E%3Cpath%20id%3D%22a%22%20d%3D%22M17.412%205.5H14.75a.75.75%200%20110-1.5h4.5a.75.75%200%2001.75.75v4.5a.75.75%200%2011-1.5%200V6.588l-7.187%207.187a.77.77%200%2001-1.088-1.088L17.412%205.5zm-1.06%207.088a.824.824%200%20011.648%200v4.941A2.47%202.47%200%200115.53%2020H6.47A2.47%202.47%200%20014%2017.53V8.47A2.47%202.47%200%20016.47%206h4.942a.824.824%200%20010%201.647H6.47a.824.824%200%2000-.824.824v9.058c0%20.455.369.824.824.824h9.058a.824.824%200%2000.824-.824v-4.94z%22%2F%3E%3C%2Fdefs%3E%3Cuse%20fill%3D%22%23404040%22%20fill-rule%3D%22evenodd%22%20xlink%3Ahref%3D%22%23a%22%2F%3E%3C%2Fsvg%3E");
        background-repeat: no-repeat;
        flex: 0 0 auto;
        margin-left: 4px;
    }

    :global(.divider) {
        background: #ededed;
        height: 1px;
        margin: 4px 0;
    }

    &:global(.dropdown-dashboard-read-only) {
        width: 136px;
    }

    &:global(.dropdown-widget-read-only) {
        width: 200px;
    }

    &:global(.dropdown-widget) {
        width: max-content;
    }
}
